<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="name"
    :name="name"
    :rules="rulesData"
    :class="isFlex ? 'd-flex w-100' : ''"
    :tag="templateTag"
  >
    <div
      class="form-group"
      :class="isFlex ? 'd-flex flex-1 flex-column' : ''"
      :style="noMarginBottom ? 'margin-bottom: 0;' : ''"
    >
      <label
        v-if="label"
        class="form-control-label"
        :class="isFlex ? 'd-flex flex-1 flex-column' : ''"
        ><strong v-if="required" class="text-danger">*</strong>
        {{ label }}</label
      >
      <div class="has-label" v-if="options.length > 0">
        <el-checkbox-group
          v-model="valueData"
          class="v-checkbox-single"
          :disabled="disabled"
        >
          <el-checkbox
            v-for="item in options"
            :key="item.value"
            :label="item.value"
          >
            {{ item.label }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div v-if="errors[0]" class="mt-2">
        <small class="text-danger">{{ errors[0] }}</small>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
export default {
  name: 'VeeCheckBox',
  props: {
    value: {
      type: [String, Number, Boolean, Array],
      default: () => '',
    },
    name: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    rules: {
      type: String,
      default: () => '',
    },
    templateTag: {
      type: String,
      default: () => 'div',
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    group: {
      type: Boolean,
      default: false,
    },
    isFlex: {
      type: Boolean,
      default: () => false,
    },
    noMarginBottom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueData: this.value,
      rulesData: this.rules,
    }
  },
  watch: {
    value() {
      this.valueData = this.value
    },
    rules(v) {
      this.rulesData = v
    },
    valueData(value) {
      this.$emit('input', value)
    },
  },
  mounted() {
    if (!this.required) {
      this.rulesData = ''
    } else {
      this.rulesData = 'required'
    }
  },
}
</script>

<style lang="scss" scoped>
.flex-1 {
  flex: 1;
}
</style>
