<template>
  <div class="wrapper">
    <notifications />
    <dashboard-navbar-left :assigned-count="counter" />
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType" />

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <nuxt />
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter" />
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar'
import { FadeTransition } from 'vue2-transitions'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

import DashboardNavbar from './components/DashboardNavbar.vue'
import ContentFooter from './components/ContentFooter.vue'
import DashboardContent from './components/Content.vue'
import DashboardNavbarLeft from "./components/DashboardNavbarLeft";
import Helper from "@/util/helper";
import { mapState } from "vuex";

function hasElement (className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar (className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`)
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

export default {
  middleware: 'auth',
  components: {
    DashboardNavbarLeft,
    DashboardNavbar,
    ContentFooter,
    DashboardContent,
    FadeTransition
  },
  data() {
    const user = this.$store.getters["auth/user"];
    return {
      unwatch: null,
      userId: user.id,
      counter: 0
    }
  },
  computed: {
    lang() {
      return this.$root.$i18n.locale;
    },
    ...mapState({
      counterChange: state => state.auth.counter
    })
  },
  watch: {
    counterChange(value){
      if(value === true){
        this.getApproveCounter()
        this.$store.dispatch("auth/updateCounter", false)
      }
    }
  },
  mounted () {
    document.body.classList.add(`lang-${this.lang}`)
    this.initScrollbar()
    this.getApproveCounter()
  },
  created() {
    this.unwatch = this.$store.watch(
      (state, getters) => getters['lang/locale'],
      (newValue, oldValue) => {
        this.$root.$i18n.locale = newValue
      },
    );
  },
  beforeDestroy() {
    this.unwatch();
  },
  methods: {
    initScrollbar () {
      const isWindows = navigator.platform.startsWith('Win')
      if (isWindows) {
        initScrollbar('sidenav')
      }
    },
     getApproveCounter() {
      const orderby = "CREATED_AT";
      const order = "ASC";

      const where = [
        { column: "ASSIGNEE_ID", operator: "EQ", value: this.userId },
        { column: "APPROVED_AT", operator: "IS_NULL" },
        { column: "DECLINED_AT", operator: "IS_NULL" },
        {
          column: "TYPE",
          operator: "IN",
          value: [
            "INTERNAL_IMPORT_CLIENT_EMPLOYEE",
            "INTERNAL_REQUEST_PIT_REPORT",
            "INTERNAL_UPDATE_CLIENT",
            "INTERNAL_MANAGE_CALCULATION",
          ]
        }
      ];

      Helper.gqlQuery("Approve", "get", {
        perPage: 1,
        page: 1,
        orderBy: [{ field: orderby, order: order }],
        where: {
          AND: where
        }
      }).then(({ data }) => {
        const pagination = data.approves.paginatorInfo;
        this.counter = pagination.total;
      });
    },
  }
}
</script>
<style lang="scss">
</style>
