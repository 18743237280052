<template></template>

<script>
import Helper from "@/util/helper";

export default {
  name: "SessionTimeout",
  computed: {},
  mounted() {
    this.initial();
  },
  data() {
    return {
      sessionTimeout: window.localStorage.getItem("session-timeout"),
    };
  },
  methods: {
    initial() {
      Helper.gqlQuery("Setting", "getAll").then(({ data }) => {
        this.sessionTimeout = data.session_timeout;
        this.setSessionTimeout(this.sessionTimeout);
        const context = this;
        const inactivityTime = function () {
          const sessionTimeout = context.sessionTimeout * 60 * 1000;
          var time = setTimeout(logout, sessionTimeout);
          document.onmousemove = resetTimer;
          document.onkeydown = resetTimer;
          document.onclick = resetTimer; // touchpad clicks
          document.onmousedown = resetTimer; // touchscreen presses
          document.addEventListener("scroll", resetTimer, true); // improved; see comments

          function logout() {
            alert("You are now logged out.");
            context.logout();
          }

          function resetTimer() {
            clearTimeout(time);
            time = setTimeout(logout, sessionTimeout);
            // 1000 milliseconds = 1 second
          }
        };
        if (context.sessionTimeout) {
          inactivityTime();
        }
      });

      return null;
    },
    setSessionTimeout(minute) {
      minute = parseInt(minute);
      if (minute && !isNaN(minute)) {
        window.localStorage.setItem("session-timeout", minute);
      } else {
        window.localStorage.removeItem("session-timeout");
      }
    },
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        window.location.assign("/dang-nhap");
      });
    },
  },
};
</script>

<style scoped></style>
