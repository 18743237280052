import {getRoutePermissions} from "~/router"
export default ({ store, redirect, route }) => {

  if (!store.getters['auth/check']) {
    return redirect('/dang-nhap')
  }

  const neededPermissions = getRoutePermissions(route.name)
  const myPermissions = store.getters['auth/permissions']

  if (neededPermissions.length) {
    let hasPermission = false
    // OR
    for (let i = 0; i < neededPermissions.length; i++) {
      const permission = neededPermissions[i]
      if (myPermissions.indexOf(permission) !== -1) {
        hasPermission = true
      }
    }
    if (!hasPermission) {
      return redirect({ name: 'home' })
    }
  }

}
