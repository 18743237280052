<template>
    <div class="form-group">
        <label v-if="label" class="form-control-label">{{label}}</label>
        <div class="has-label">
            <el-date-picker
                v-model="valueData"
                :type="type"
                :format="format"
                :value-format="valueFormat"
                :placeholder="placeholder"
                :disabled="disabled" v-bind="$attrs">
            </el-date-picker>
        </div>
    </div>
</template>
<script>
export default {
    name: 'DateInput',
    props: {
        value: {
            type: String,
            default: () => ('')
        },
        name: {
            type: String,
            default: () => ('')
        },
        label: {
            type: String,
            default: () => ('')
        },
        placeholder: {
            type: String,
            default: () => ('')
        },
        disabled: {
            type: Boolean,
            default: () => (false)
        },
        format: {
            type: String,
            default: () => ('dd/MM/yyyy')
        },
        valueFormat: {
            type: String,
            default: () => ('yyyy-MM-dd')
        },
        type: {
            type: String,
            default: () => ('date')
        },
        languageClass: {
            type: String,
            default: () => ('iglobalDatePicker-en')
        }
    },
    data() {
        return {
            valueData: this.value
        }
    },
    watch: {
        value() {
            this.valueData = this.value
        },
        valueData(value) {
            this.$emit('input', value)
        }
    },
}
</script>
<style>
</style>
