<template>
  <ValidationProvider
    :vid="name"
    :name="name"
    :rules="rules"
    v-slot="{ errors }"
    :class="isFlex ? 'd-flex' : ''"
    :tag="templateTag"
  >
    <div
      class="form-group"
      :class="isFlex ? 'd-flex flex-1 flex-column' : ''"
      :style="noMarginBottom ? 'margin-bottom: 0;' : ''"
    >
      <label
        v-if="label"
        class="form-control-label"
        :class="[isFlex ? 'd-flex flex-1 flex-column' : '', labelClasses]"
      >
        <strong v-if="required" class="text-danger">*</strong>
        {{ label }}</label
      >
      <div class="has-label" :style="isBreak ? 'flex-basis: 55%;' : ''">
        <el-date-picker
          ref="picker"
          v-model="valueData"
          :type="type"
          :format="
            type === 'monthrange'
              ? format
              : type === 'month'
              ? format
              : type === 'year'
              ? format
              : type === 'day'
              ? formatByLocale
              : formatByLocale
          "
          :value-format="valueFormat"
          :placeholder="placeholder"
          :picker-options="{ disabledDate: disabledDate }"
          :disabled="disabled"
          :clearable="clearable"
          v-bind="$attrs"
          :required="required"
          @change="() => $emit('change', valueData)"
          :style="styleCustom"
        >
        </el-date-picker>
      </div>
      <div v-if="required && errors[0]" class="mt-2">
        <small class="text-danger">{{ errors[0] }}</small>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
const moment = require('moment')
import { mapGetters } from 'vuex'

export default {
  name: 'VeeDate',
  props: {
    value: {
      type: [String, Date, Number, Array],
      default: () => '',
    },
    name: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    templateTag: {
      type: String,
      default: () => 'div',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    rules: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    format: {
      type: String,
      default: () => 'dd/MM/yyyy',
    },
    valueFormat: {
      type: String,
      default: () => 'yyyy-MM-dd',
    },
    type: {
      type: String,
      default: () => 'date',
    },
    isBeforeToday: {
      type: Boolean,
      default: () => false,
    },
    isBeforeTomorrow: {
      type: Boolean,
      default: () => false,
    },
    minDay: {
      type: String,
      default: () => '',
    },
    selectDate: {
      type: [Date, String],
      default: () => '',
    },
    isBreak: {
      type: Boolean,
      default: () => false,
    },
    isFlex: {
      type: Boolean,
      default: () => false,
    },
    styleCustom: {
      type: Object,
      default: () => {},
    },
    noMarginBottom: {
      type: Boolean,
      default: false,
    },
    labelClasses: {
      type: String,
      default: () => '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      valueData: this.value,
    }
  },
  computed: {
    ...mapGetters({
      selectedLang: 'lang/locale',
    }),
    formatByLocale() {
      return this.selectedLang !== 'ja' ? 'dd/MM/yyyy' : 'yyyy-MM-dd'
    },
  },
  watch: {
    value() {
      this.valueData = this.value
    },
    valueData(value) {
      this.$emit('input', value)
    },
  },
  mounted() {
    // this.valueData = this.selectDate
  },
  methods: {
    disabledDate(date) {
      if (this.isBeforeToday) return moment(date).isBefore(moment(), 'day')
      if (this.isBeforeTomorrow) return moment(date).isBefore(moment().add(1, 'days'), 'day')
      if (this.minDay !== '') {
        return moment(date).isBefore(moment(this.minDay), 'day')
      }

      return false
    },
  },
}
</script>

<style lang="scss">
.flex-1 {
  flex: 1;
}
</style>
