<template>
  <div :class="{[`bg-${type}`]: type}" class="header">
    <div class="container">
      <div class="header-body">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BoxHeader',
  props: {
    type: {
      type: String,
      default: 'primary',
      description: 'Header background type'
    }
  }
}
</script>