import Vue from 'vue'
// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import '~/polyfills'
// Notifications plugin. Used on Notifications page
// Validation plugin used to validate forms
import { configure } from 'vee-validate'
// A plugin file where you could register global components used across the app
require('./globalComponents')
// A plugin file where you could register global directives
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue

import SideBar from '~/components/SidebarPlugin'
import Notifications from '~/components/NotificationPlugin'
import clickOutside from '~/directives/click-ouside.js'


Vue.directive('click-outside', clickOutside)
export default {
  install (Vue) {
    Vue.use(SideBar)
    Vue.use(Notifications)
    configure({
      classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'] // multiple classes per flag!
      }
    })
  }
}
