import Helper from '@/util/helper'
import Vue from "vue"

const Plugin = {}
Plugin.install = function (Vue, options) {
    Vue.prototype.$gqlQuery = async function (schema, action, variables, silent) {
        const {data} = await Helper.gqlQuery(schema, action, variables, silent)
        return data;
    }

    Vue.prototype.$gqlMutate = async function (schema, action, variables, silent) {
        const {data} = await Helper.gqlMutate(schema, action, variables, silent)
        return data;
    }

    Vue.prototype.$getClientEmployee = async function (id) {
        const ce = this.$store.state.auth.clientEmployees.find((v) => v.id === id)
        if (ce) {
          return ce
        }
        const res = await this.$gqlQuery('ClientEmployee', 'find', {
          id,
        })
        const employee = res.clientEmployee ? res.clientEmployee : null
        if (employee) {
          await this.$store.dispatch('auth/cacheClientEmployee', employee)
        }
        return employee
      }
}

Vue.use(Plugin);
