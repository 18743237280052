<template>
  <side-bar>
    <template slot="links" slot-scope="props">
      <sidebar-item
        v-if="['role', 'staff', 'leader', 'director'].includes(role)"
        :link="{
          name: $t('progress_management'),
          icon: 'fal fa-chart-pie',
          path: '/bao-cao-tien-do',
        }"
      />
      <sidebar-item
        v-if="roleManager.canAccessQuanLyKeToan()"
        :link="{
          name: $t('model.clients.payroll'),
          icon: 'fal fa-badge-dollar',
          path: '/quan-ly-ke-toan',
        }"
      />

      <sidebar-item
        v-if="roleManager.canAccessKhachHang()"
        :link="{
          name: $t('customer_in_charge'),
          icon: 'fal fa-users',
          path: '/khach-hang',
        }"
      />

      <sidebar-item
        v-if="roleManager.canAccessYeuCauHoTro()"
        :link="{
          name: $t('request_support'),
          icon: 'fal fa-square-question',
          path: '/yeu-cau-ho-tro',
        }"
      />

      <sidebar-item
        v-if="roleManager.canAccessFAQ()"
        :link="{
          name: $t('question_library_QA'),
          icon: 'fal fa-books',
          path: '/FAQ',
        }"
      />

      <sidebar-item
        v-if="roleManager.canAccessCongThucChung()"
        :link="{
          name: $t('formula_function'),
          icon: 'fal fa-flask',
          path: '/quan-ly-cong-thuc-chung',
        }"
      />

      <sidebar-item
        v-if="roleManager.canAccessThuVienCauHoi()"
        :link="{
          name: $t('question_library'),
          icon: 'fal fa-books',
          path: '/thu-vien-cau-hoi',
        }"
      />

      <sidebar-item
        v-if="roleManager.canAccessSalaryStatistics()"
        :link="{
          name: $t('statistics_accounts_expenses'),
          icon: 'fal fa-money-bill-1-wave',
          path: '/thong-ke-giai-doan-tinh-luong',
        }"
      />

      <h6 class="navbar-heading p-0 text-muted mt-md-4">
        <span class="text">{{ $t('management') }}</span>
        <span class="icon"><font-awesome-icon icon="fal fa-ellipsis" /></span>
      </h6>

      <sidebar-item
        :link="{
          name: $t('model.buttons.approved_request'),
          icon: 'fal fa-clipboard-check',
          path: '/yeu-cau-duyet',
          badge: assignedCount,
        }"
      />

      <!--      <sidebar-item-->
      <!--        :link="{-->
      <!--          name: $t('request_approve'),-->
      <!--          icon: '/img/icons/dollar.png',-->
      <!--          path: '/yeu-cau-duyet'-->
      <!--        }"-->
      <!--      />-->

      <sidebar-item
        v-if="roleManager.canAccessQuanLyKhachHangMoi()"
        :link="{
          name: $t('manage_new_customer'),
          icon: 'fal fa-user-check',
          path: '/quan-ly-khach-hang-moi',
        }"
      />

      <sidebar-item
        v-if="roleManager.canAccessQuanLyNhanVienIGLocal()"
        :link="{
          name: $t('model.employees.list'),
          icon: 'fal fa-users',
          path: '/nhan-vien',
        }"
      />
      <sidebar-item
        v-if="roleManager.canAccessQuanLyKhachHangMoi()"
        :link="{
          name: $t('model.clients.list'),
          icon: 'fal fa-users-medical',
          path: '/quan-ly-danh-sach-khach-hang',
        }"
      />
      <!--      <sidebar-item-->
      <!--        v-if="roleManager.canAccessKhachHang()"-->
      <!--        :link="{-->
      <!--          name: $t('customer_in_charge'),-->
      <!--          icon: '/img/icons/icon3.png',-->
      <!--          path: '/yeu-cau-duyet'-->
      <!--        }"-->
      <!--      />-->

      <sidebar-item
        v-if="roleManager.canAccessDebitRequest()"
        :link="{
          name: $t('expense_management'),
          icon: 'fal fa-money-check-dollar-pen',
          path: '/quan-ly-chi-ho',
        }"
      />

      <!--      <sidebar-item-->
      <!--        v-if="roleManager.canAccessCongThucChung()"-->
      <!--        :link="{-->
      <!--          name: $t('library'),-->
      <!--          icon: '/img/icons/icon5.png',-->
      <!--          path: '/khach-hang/cong-thuc-chung'-->
      <!--        }"-->
      <!--      />-->

      <!--      <sidebar-item-->
      <!--        v-if="roleManager.canAccessThuVienCauHoi()"-->
      <!--        :link="{-->
      <!--          name: $t('question_library'),-->
      <!--          icon: '/img/icons/icon6.png',-->
      <!--          path: '/thu-vien-cau-hoi'-->
      <!--        }"-->
      <!--      />-->

      <sidebar-item
        v-if="roleManager.canAccessNgayNghiLe()"
        :link="{
          name: $t('holidays'),
          icon: 'fal fa-calendar-alt',
          path: '/ngay-nghi-le',
        }"
      />

      <sidebar-item
        v-if="roleManager.canAccessQuanLyTemplate()"
        :link="{
          name: $t('template'),
          icon: 'fal fa-file-pen',
          path: '/quan-ly-template',
        }"
      />

      <!-- <sidebar-item
        v-if="roleManager.canAccessQuanLyTemplate()" -->
      <!-- // Todo: role manager category -->
      <sidebar-item
        v-if="roleManager.canAccessQuanLyTemplate()"
        :link="{
          name: $t('manage_folders'),
          icon: 'fal fa-folders',
          path: '/quan-ly-thu-muc',
        }"
      />
      <!--      <sidebar-item-->
      <!--        v-if="roleManager.canAccessQuanLyTemplate()"-->
      <!--        :link="{-->
      <!--          name: $t('manage_email_templates'),-->
      <!--          icon: '/img/icons/icon8.png',-->
      <!--          path: '/quan-ly-email-template'-->
      <!--        }"-->
      <!--      />-->

      <sidebar-item
        v-if="roleManager.canAccessQuanLyPhanQuyen()"
        :link="{
          name: $t('model.employee_iglocal.permission'),
          icon: 'fal fa-user-lock',
          path: '/quan-ly-phan-quyen',
        }"
      />

      <sidebar-item
        v-if="roleManager.canAccessThongKeLuong()"
        :link="{
          name: $t('salary_statistics'),
          icon: 'fal fa-chart-line',
          path: '/thong-ke-luong',
        }"
      />

      <sidebar-item
        v-if="roleManager.canAccessTranslation()"
        :link="{
          name: $t('transition'),
          icon: 'fal fa-language',
          path: '/translation',
        }"
      />

      <sidebar-item
        v-if="roleManager.canAccessQuanLyKhachHangMoi()"
        :link="{
          name: $t('quan_ly_danh_sach_ngan_hang'),
          icon: 'fal fa-building-columns',
          path: '/quan-ly-ngan-hang',
        }"
      />

      <sidebar-item
        v-if="roleManager.canAccessQuanLyKhachHangMoi()"
        :link="{
          name: $t('manage_hospital'),
          icon: 'fal fa-building-columns',
          path: '/quan-ly-benh-vien',
        }"
      />

      <sidebar-item
        v-if="roleManager.canAccessQuanLyThuVienCauHoi()"
        :link="{
          name: $t('question_library'),
          icon: 'fal fa-books',
          path: '/quan-ly-thu-vien-cau-hoi',
        }"
      />

      <sidebar-item
        :link="{
          name: $t('settings'),
          icon: 'fal fa-gear',
          path: '/action-log',
        }"
      />
      <CheckConnection />

      <!--      <sidebar-item-->
      <!--        :link="{-->
      <!--          name: $t('mobile_setting'),-->
      <!--          path: '/mobile-setting',-->
      <!--          icon: '/img/icons/icon5.png'-->
      <!--        }"-->
      <!--      />-->
    </template>
  </side-bar>
</template>
<script>
import RoleManager from '@/util/RoleManager'
import CheckConnection from '@/../frontend-common/components/CheckConnection'
export default {
  name: 'DashboardNavbarLeft',
  components: {
    CheckConnection,
  },
  props: {
    assignedCount: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    const user = this.$store.getters['auth/user']
    const permissions = this.$store.state.auth.permissions

    return {
      role: user.role,
      userPermissions: this.$store.state.auth.permissions,
      roleManager: RoleManager(user.role, permissions),
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push({ name: 'dang-nhap' })
      })
    },
  },
}
</script>
<style scoped lang="scss">
.navbar-heading {
  font-size: 13px;
  text-transform: inherit;
  color: rgba(120, 120, 120, 1);
  background: rgba(241, 241, 241, 1);

  font-family: Roboto;
  font-weight: 400;

  span {
    padding: 5px 1.5rem;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.icon {
      width: 50px;
      height: auto;
      display: none;

      svg {
        padding-top: 3px;
        padding-bottom: 3px;
        font-size: 28px;
      }
    }
  }
}

.navbar-height {
  padding-bottom: 50px;
  height: calc(100% - 50px);
  //overflow-y: auto;
  position: relative;
}

.other-navbar-nav {
  position: absolute;
  bottom: 0;
  left: 24px;
  right: 24px;
  width: calc(100% - 50px);
  .nav-item {
    list-style: none;
    margin-bottom: 8px;
    margin-left: -12px;
    margin-right: -12px;
    .nav-link {
      border: 1px solid rgba(226, 226, 226, 1);
      border-radius: 4px;
      color: #707070;
      font-family: Roboto;
      font-weight: 300;
      display: flex;
    }
  }
}
</style>
