// state

export const state = () => ({
  currentContext: '',
  clientContext: {
    id: null,
    client: {}
  }
})

// getters
export const getters = {
  currentContext: state => state.currentContext,
  client: state => state.currentContext === 'clientContext' && state.clientContext.client ?
                    state.clientContext.client : null,
  clientId: state => state.clientContext.id ? state.clientContext.id : null,
}

// mutations
export const mutations = {
  UPDATE_CLIENT_CONTEXT (state, client) {
    if (client.id) {
      state.clientContext.id = client.id
      state.clientContext.client = client
    }
  },
  UPDATE_CURRENT_CONTEXT (state, currentContext) {
    state.currentContext = currentContext
  }
}

export const actions = {
  setClientContext ({ commit }, client ) {
    commit('UPDATE_CURRENT_CONTEXT', 'clientContext')
    commit('UPDATE_CLIENT_CONTEXT', client)
  },
  clearContext ({state, commit}) {
    if (state.currentContext !== '') {
      commit('UPDATE_CURRENT_CONTEXT', '')
    }
  }
}
