
import api from '@/util/api'
import Cookies from 'js-cookie'

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

export default ({ app, store, redirect }, inject) => {

    if (process.server) {
        return
    }

    api.getToken = () => {

        let token = Cookies.get('token')

        if( !token ) {
            token = store.getters['auth/token']
        }

        return token
    }

}
