export default {
  API_VERSION: "v1",
  API_URL: process.env.graphqlUrl,
  API_DATE_FORMAT: "YYYY-MM-DD",
  DATE_FORMAT: "DD/MM/YYYY",
  INTERNAL_DUMMY_CLIENT_ID: "000000000000000000000000",
  TINYMCE_API_KEY: "0gzgdn59wv903mkk21h6l2ndf3wjxh1a05kymyubhqj57izq",
  TRINH_DO_CHUYEN_MON: [
    { value: "khong-yeu-cau", label: "Không yêu cầu" },
    { value: "tot-nghiep-cap-ptcs", label: "Tốt nghiệp cấp PTCS" },
    { value: "tot-nghiep-cap-ptth", label: "Tốt nghiệp cấp PTTH" },
    { value: "trung-cap", label: "Trung cấp" },
    { value: "cao-dang", label: "Cao đẳng" },
    { value: "dai-hoc", label: "Đại học" },
    { value: "thac-si", label: "Thạc sĩ" },
    { value: "tien-si", label: "Tiến sĩ" },
  ],
  PAYROLL_TIMES: [
    { value: "this_month", label: "Trong tháng này" },
    { value: "date_range", label: "Chọn từ ngày tới ngày" },
  ],
  PAYROLL_KIND_PAYMENTS: [
    { value: "fulltime", label: "Toàn thời gian" },
    { value: "partime", label: "Bán thời gian" },
  ],
  PAYROLL_CACULATIONS: [
    { value: "method_1", label: "Số ngày trong tháng trừ chủ nhật" },
    { value: "method_2", label: "Số ngày trong tháng trừ T7, CN" },
    { value: "method_3", label: "Số ngày trong tháng trừ nửa T7 và ngày CN" },
    { value: "method_4", label: "Số ca được phân trong tháng" },
    { value: "method_5", label: "Ngày cố đinh" },
  ],
  PAYROLL_STATUS: [
    { value: "creating", label: "Đang tạo", color: "text-purple" },
    { value: "new", label: "Tạo mới", color: "text-purple" },
    { value: "processed", label: "Đã tính lương", color: "text-purple" },
    {
      value: "director_review",
      label: "Chờ quản lý duyệt",
      color: "text-purple",
    },
    {
      value: "director_approced",
      label: "Quản lý đã duyệt",
      color: "text-warning",
    },
    {
      value: "client_review",
      label: "Chờ khách hàng duyệt",
      color: "text-purple",
    },
    {
      value: "client_approved",
      label: "Khách hàng đã duyệt",
      color: "text-info",
    },
    { value: "paid", label: "Đã thanh toán", color: "text-success" },
    {
      value: "director_declined",
      label: "Quản lý từ chối",
      color: "text-danger",
    },
    {
      value: "leader_declined",
      label: "Trưởng nhóm từ chối",
      color: "text-danger",
    },
    {
      value: "client_rejected",
      label: "Khách hàng từ chối",
      color: "text-danger",
    },
    { value: "limited", label: "Vượt giới hạn", color: "text-danger" },
    { value: "error", label: "Lỗi", color: "text-danger" },
  ],
  MARITAL_STATUS: [
    { value: "married", label: "Đã kết hôn" },
    { value: "single", label: "Độc thân" },
  ],
  //
  // @deprecated, please use this.$getOptions('employeeSalaryStates') instead
  //
  EMPLOYEE_SALARY_STATES: [
    { label: "Đang làm việc", value: "đang làm việc" },
    { label: "Nghỉ không lương", value: "nghỉ không lương" },
    { label: "Nghỉ thai sản", value: "nghỉ thai sản" },
    { label: "Nghỉ việc", value: "nghỉ việc" },
  ],
  EMPLOYEE_ROLES: [
    { label: "Quản lý", value: "manager" },
    { label: "Kế toán", value: "accountant" },
    { label: "Nhân viên", value: "staff" },
    { label: "Trưởng nhóm", value: "leader" },
    { label: "HR", value: "hr" },
  ],
  EMPLOYEE_WORK_STATUS: [
    { label: "Đi làm", value: "Đi làm" },
    { label: "Công tác", value: "Công tác" },
    { label: "Nghỉ phép HL", value: "Nghỉ phép HL" },
    { label: "Nghỉ phép KHL", value: "Nghỉ phép KHL" },
    { label: "Nghỉ lễ", value: "Nghỉ lễ" },
    { label: "Nghỉ cuối tuần", value: "Nghỉ cuối tuần" },
  ],
  EMPLOYEE_WORK_STATES: [
    "early", // 'Về sớm'
    "late", // 'Đi trễ'
    "half", // 'Nghỉ nữa buổi',
  ],
  SUPPORT_STATUS: [
    { label: "Mới", value: "new" },
    { label: "Đã trả lời", value: "answered" },
    { label: "Mở lại", value: "open_again" },
    { label: "Đóng", value: "close" },
  ],
  SYSTEM_VARIABLES: [
    {
      variable_name: "S_TIMESHEET_WORK_HOURS",
      readable_name: "Số giờ làm việc cơ bản trong tháng",
      variable_value: 0,
    },
    {
      variable_name: 'S_SHIFT',
      readable_name: 'Số công cơ bản',
    },
    {
      variable_name: 'S_NIGHT_SHIFT',
      readable_name: 'Số công ban đêm',
    },
    {
      variable_name: 'S_HOLIDAY_SHIFT',
      readable_name: 'Số công ngày lễ',
    },
    {
      variable_name: "S_OFF_HOURS_WITH_SALARY",
      readable_name: "Số giờ nghỉ phép có hưởng lương",
      variable_value: 0,
    },
    {
      variable_name: "S_OFF_HOURS_NO_SALARY",
      readable_name: "Số giờ nghỉ phép không hưởng lương",
      variable_value: 0,
    },
    {
      variable_name: "S_WORK_HOURS_OT_WEEKDAY",
      readable_name: "Số giờ làm thêm các ngày trong tuần",
      variable_value: 0,
    },
    {
      variable_name: "S_WORK_HOURS_OT_WEEKEND",
      readable_name: "Số giờ làm thêm các ngày cuối tuần",
      variable_value: 0,
    },
    {
      variable_name: "S_WORK_HOURS_OT_HOLIDAY",
      readable_name: "Số giờ làm thêm ngày Lễ",
      variable_value: 0,
    },
    {
      variable_name: "S_TIMESHEET_TRAVEL_WORK_HOURS",
      readable_name: "Số giờ đi công tác",
      variable_value: 0,
    },
    {
      variable_name: "S_SALARY",
      readable_name: "Lương cơ bản",
      variable_value: 0,
    },
    {
      variable_name: "S_ALLOWANCE_RESPONSIBILITIES",
      readable_name: "Phụ cấp trách nhiệm",
      variable_value: 0,
    },
    {
      variable_name: "S_ALLOWANCE_FIXED",
      readable_name: "Phụ cấp cố định",
      variable_value: 0,
    },
    {
      variable_name: "S_IS_TAXABLE",
      readable_name: "Đối tượng tính thuế",
      variable_value: 0,
    },
    {
      variable_name: "S_IS_INSURANCE_APPLIED",
      readable_name: "Đối tượng đóng BHXH, BHYT, BHTN",
      variable_value: 0,
    },
    {
      variable_name: "S_NUMBER_OF_DEPENDENTS",
      readable_name: "Số người phụ thuộc",
      variable_value: 0,
    },
    {
      variable_name: "S_INSURANCE_SALARY",
      readable_name: "Mức đóng bảo hiểm xã hội",
      variable_value: 0,
    },
    {
      variable_name: "S_STANDARD_WORK_HOURS",
      readable_name: "Số giờ làm việc tiêu chuẩn",
      variable_value: 0,
    },
    {
      variable_name: "S_SH_INSURANCE_MAXIMUM",
      readable_name: "Mức trần BHXH/BHYT",
      variable_value: 0,
    },
    {
      variable_name: "S_U_INSURANCE_MAXIMUM",
      readable_name: "Mức trần BHTN",
      variable_value: 0,
    },
    {
      variable_name: "S_POSITION",
      readable_name: "Chức vụ",
      input_type: "text",
      variable_value: "",
    },
    {
      variable_name: "S_TOTAL_WORK_HOURS_OT_AT_NIGHT",
      readable_name: "Tổng số giờ OT ban đêm",
      variable_value: 0,
    },
    {
      variable_name: "S_WORK_HOURS_OT_AT_NIGHT_WEEKDAY",
      readable_name: "Số giờ OT ban đêm vào ngày thường",
      variable_value: 0,
    },
    {
      variable_name: "S_WORK_HOURS_OT_AT_NIGHT_WEEKEND",
      readable_name: "Số giờ OT ban đêm vào ngày cuối tuần",
      variable_value: 0,
    },
    {
      variable_name: "S_WORK_HOURS_OT_AT_NIGHT_HOLIDAY",
      readable_name: "Số giờ OT ban đêm vào ngày Lễ/Tết",
      variable_value: 0,
    },
    {
      variable_name: 'S_WORK_HOURS_OT_AT_ONLY_NIGHT_WEEKDAY',
      readable_name: 'Số giờ chỉ OT ban đêm vào ngày thường',
      variable_value: 0,
    },
    {
      variable_name: 'S_WORK_HOURS_OT_AT_ONLY_NIGHT_WEEKEND',
      readable_name: 'Số giờ chỉ OT ban đêm vào ngày cuối tuần',
      variable_value: 0,
    },
    {
      variable_name: 'S_WORK_HOURS_OT_AT_ONLY_NIGHT_HOLIDAY',
      readable_name: 'Số giờ chỉ OT ban đêm vào ngày Lễ/Tết',
      variable_value: 0,
    },
    {
      variable_name: "S_TOTAL_WORK_HOURS_AT_NIGHT",
      readable_name: "Tổng thời gian làm việc vào ban đêm",
      variable_value: 0,
    },
    {
      variable_name: "S_SPECIAL_OT_WEEKEND",
      readable_name: 'Số giờ OT ban ngày T7 CN có status là "Đi làm"',
      variable_value: 0,
    },
    {
      variable_name: "S_SPECIAL_OT_AT_NIGHT_WEEKEND",
      readable_name: 'Số giờ OT ban đêm T7 CN có status là "Đi làm',
      variable_value: 0,
    },
    {
      variable_name: "S_TOTAL_WORK_HOURS_OT_OF_YEAR",
      readable_name:
        "Tổng thời gian OT trong năm (tính đến thời điểm hiện tại)",
      variable_value: 0,
    },
    {
      variable_name: "S_OT_SAR",
      readable_name: "Số giờ OT ngày T7",
    },
    {
      variable_name: "S_TIMESHEET_START_DATE",
      readable_name: "Thời gian bắt đầu tính lương đã chọn khi tạo bảng lương",
      input_type: "date",
      variable_value: "",
    },
    {
      variable_name: "S_TIMESHEET_END_DATE",
      readable_name: "Thời gian kết thúc tính lương đã chọn khi tạo bảng lương",
      input_type: "date",
      variable_value: "",
    },
    {
      variable_name: "S_SALARY_CURRENCY",
      readable_name: "Loại tiền tệ của mức lương cơ bản",
      variable_value: "",
      input_type: "text",
    },

    {
      variable_name: "S_REMAIN_DAYS_LEAVE_IN_YEAR",
      readable_name: "Số ngày phép còn lại trong năm",
      variable_value: 0,
    },
    {
      variable_name: "S_DEPARTMENT",
      readable_name: "Phòng ban",
      variable_value: "",
      input_type: "text",
    },
    {
      variable_name: 'S_NATIONALITY',
      readable_name: 'Quốc tịch',
    },
    {
      variable_name: 'S_TITLE',
      readable_name: 'Chức danh',
    },
    {
      variable_name: "S_TYPE_OF_LABOUR_CONTRACT",
      readable_name: "Loại hợp đồng lao động",
      variable_value: 0,
    },
    {
      variable_name: "S_TYPE_OF_WORKING_STATUS",
      readable_name: "Tình trạng làm việc",
      variable_value: "",
      input_type: "text",
    },
    {
      variable_name: "S_GENDER",
      readable_name: "Giới tính",
      variable_value: "",
      input_type: "text",
    },
    {
      variable_name: "S_ALLOWANCE",
      readable_name: "Tổng phụ cấp / khấu trừ",
      variable_value: 0,
    },
    {
      variable_name: "S_WORK_FROM_HOME_DAYS",
      readable_name: "Số ngày làm việc tại nhà",
      variable_value: 0,
    },
    {
      variable_name: "S_STANDARD_WORKING_DAYS",
      readable_name: "Số ngày công tiêu chuẩn trong tháng",
      variable_value: 0,
    },
    {
      variable_name: "S_TIMESHEET_WORKING_DAYS",
      readable_name: "Số ngày làm việc thực tế trong tháng",
      variable_value: 0,
    },
    {
      variable_name: "S_MENSTRUAL_LEAVE_HOURS",
      readable_name: "Số giờ nghỉ hành kinh trong tháng",
      variable_value: 0,
    },
    {
      variable_name: "S_SENIORITY",
      readable_name: "Thâm niên",
      variable_value: 0,
    },

    {
      variable_name: "R_DEPARTMENT_EMPLOYEES",
      readable_name: "Tổng số nhân viên của department",
      variable_value: 0,
    },
    {
      readable_name: "Số giờ đi công tác có phí",
      variable_name: "S_BUSINESS_TRIP_HAS_FEE_HOURS",
      variable_value: 0,
    },
    {
      readable_name: "Số giờ đi công tác không có phí",
      variable_name: "S_BUSINESS_TRIP_NO_FEE_HOURS",
      variable_value: 0,
    },
    {
      readable_name: "Tháng tạo bảng lương",
      variable_name: "S_MONTH",
    },
    {
      readable_name: "Năm tạo bảng lương",
      variable_name: "S_YEAR",
    },
    {
      readable_name: "Số ngày có checkin",
      variable_name: "S_CHECK_IN_COUNT_DAYS",
    },
    {
      readable_name: "Số ngày checkin đúng giờ",
      variable_name: "S_CHECK_IN_INTIME_DAYS",
    },
    {
      readable_name: "Số ngày checkin trễ giờ",
      variable_name: "S_CHECK_IN_LATE_DAYS",
    },
    {
      readable_name: 'Số ngày checkin trễ giờ với core time',
      variable_name: 'S_CHECK_IN_LATE_CORE_TIME_DAYS',
    },
    {
      readable_name: 'Số ngày có checkout',
      variable_name: 'S_CHECK_OUT_COUNT_DAYS',
    },
    {
      readable_name: 'Số ngày checkout sớm',
      variable_name: 'S_CHECK_OUT_EARLY_DAYS',
    },
    {
      readable_name: 'Số ngày checkout sớm',
      variable_name: 'S_CHECK_OUT_EARLY_CORE_TIME_DAYS',
    },
    {
      readable_name: "Tên người thụ hưởng của số tài khoản",
      variable_name: "S_BANK_ACCOUNT_OF_EMPLOYEES",
    },
    {
      readable_name: "Số tài khoản ngân hàng của nhân viên",
      variable_name: "S_BANK_ACCOUNT_NUMBER_OF_EMPLOYEES",
    },
    {
      readable_name: "Tên ngân hàng số tài khoản của nhân viên",
      variable_name: "S_BANK_NAME_OF_EMPLOYEES",
    },
    {
      readable_name: "Chi nhánh ngân hàng số tài khoản của nhân viên",
      variable_name: "S_BANK_BRANCH_NAME_OF_EMPLOYEES",
    },
    {
      readable_name: 'Số giờ nghỉ phép năm có hưởng lương',
      variable_name: 'S_HOURS_PAID_ANNUAL_LEAVE',
    },
    {
      readable_name: 'Số giờ nghỉ phép để kết hôn có hưởng lương',
      variable_name: 'S_HOURS_GET_MARRIED_PAID_LEAVE',
    },
    {
      readable_name: 'Số giờ nghỉ phép để con cái kết hôn có hưởng lương',
      variable_name: 'S_HOURS_GET_CHILD_MARRIAGE_PAID_LEAVE',
    },
    {
      readable_name: 'Số giờ nghỉ phép do người thân mất có hưởng lương',
      variable_name: 'S_HOURS_FAMILY_LOST_PAID_LEAVE',
    },
    {
      readable_name: 'Số giờ nghỉ phép với chế độ mang thai có hưởng lương',
      variable_name: 'S_HOURS_PREGNANT_PAID_LEAVE',
    },
    {
      readable_name: 'Số giờ nghỉ phép hành kinh có hưởng lương',
      variable_name: 'S_HOURS_WOMAN_PAID_LEAVE',
    },
    {
      readable_name: 'Số giờ nghỉ phép với chế độ con nhỏ có hưởng lương',
      variable_name: 'S_HOURS_BABY_CARE_PAID_LEAVE',
    },
    {
      readable_name: 'Số giờ nghỉ bù có hưởng lương',
      variable_name: 'S_HOURS_CHANGED_PAID_LEAVE',
    },
    {
      readable_name: 'Số giờ nghỉ với lý do khác có hưởng lương',
      variable_name: 'S_HOURS_OTHER_PAID_LEAVE',
    },
    {
      readable_name: 'Số giờ nghỉ phép không hưởng lương',
      variable_name: 'S_HOURS_NO_SALARY_INDIVIDUAL',
    },
    {
      readable_name: 'Số giờ nghỉ phép thai sản không hưởng lương',
      variable_name: 'S_HOURS_NO_SALARY_PREGNANT',
    },
    {
      readable_name: 'Số giờ nghỉ phép ốm BHXH không hưởng lương',
      variable_name: 'S_HOURS_NO_SALARY_SELF_SICK',
    },
    {
      readable_name: 'Số giờ nghỉ phép BHXH do con ốm không hưởng lương',
      variable_name: 'S_HOURS_NO_SALARY_CHILD_SICK',
    },
    {
      readable_name: 'Số giờ nghỉ phép BHXH do vợ đẻ không hưởng lương',
      variable_name: 'S_HOURS_NO_SALARY_WIFE_PREGNANT',
    },
    {
      readable_name: 'Số giờ nghỉ phép đi khám thai không hưởng lương',
      variable_name: 'S_HOURS_NO_SALARY_PRENATAL_CHECKUP',
    },
    {
      readable_name: 'Số giờ nghỉ phép khác không hưởng lương',
      variable_name: 'S_HOURS_NO_SALARY_OTHER',
    },
    {
      readable_name: 'Đếm số lượng ngày OT cuối tuần trên công ty (có check_in)',
      variable_name: 'S_OT_W_KEND_COUNT',
    },
    {
      readable_name: 'Đếm số lượng ngày OT cuối tuần ngoài công ty',
      variable_name: 'S_OT_W_END_OUTSIDE_COUNT',
    },
    {
      readable_name: 'Đếm số lượng ngày đi công tác có phí',
      variable_name: 'S_BUSINESS_TRIP_HAS_FEE_DAY',
    },
    {
      readable_name: 'Số ngày có checkin/out vào ngày nghỉ và nghỉ lễ',
      variable_name: 'S_COUNT_DAY_OF_HOLIDAY_OR_OFF_DAY_WHEN_CHECK_IN_OUT',
    },
    {
      readable_name: 'Số giờ đi trễ về sớm',
      variable_name: 'S_LATE_HOURS',
    },
    {
      readable_name: "Số giờ xin đơn không hưởng lương",
      variable_name: "S_UNPAID_LEAVE_HOURS",
    },
    {
      readable_name: "Số giờ xin đơn không hưởng lương",
      variable_name: "S_UNPAID_LEAVE_HOURS",
    },
    {
      readable_name: "Số ngày lễ",
      variable_name: "S_COUNT_HOLIDAY",
    },
    {
      readable_name: "Ngày vào công ty",
      variable_name: "S_DATE_OF_ENTRY",
    },
    {
      readable_name: "Tuổi",
      variable_name: "S_AGE",
    },
    {
      readable_name: "Ngày sinh",
      variable_name: "S_DATE_OF_BIRTH",
    },
    {
      readable_name: "Số CCCD/CMND",
      variable_name: "S_ID_CARD_NUMBER",
    },
    {
      readable_name: "Số giờ nghỉ phép do nghỉ bệnh không hưởng lương",
      variable_name: "S_HOURS_NO_SALARY_SICK",
    },
  ],
  SYSTEM_VARIABLE_CALCULATED_VALUE: "calculated_value", // total final
  SAMPLE_FORMULA_FOR_ALL: [
    {
      name: "Cách tính thuế",
      func_name: "tinhthue",
      description: "cách tính thuế",
      parameters: ["OFF_HOURS_WITH_SALARY", "WORK_HOURS_BASIC"],
      formula:
        "WORK_HOURS_BASIC + OFF_HOURS_WITH_SALARY + IF(WORK_HOURS_OT_WEEKDAY==1, 2, 1)",
    },
  ],
  VALIDATE_IMPORTING: {
    "The {msg} field is required.": "required_msg",
    "The selected {msg} is invalid.": "invalid_msg",
    "The {msg} must be a string.": "must_str_msg",
    "The {msg} must be an integer.": "must_num_msg",
    "The {msg} must be a number.": "must_num_msg",
    "The {msg} must be a valid email address.": "must_valid_email_msg",
    "The {msg} is not a valid date.": "must_date_msg",
    "The {msg} has already been taken.": "already_taken_msg",
    "The {msg} must be at least 0.": "min_num_zero_msg",
    "The {msg} does not match the format Y-m-d.": "must_valid_date_msg",
  },
  CLIENT_CUSTOM_VARIABLE_SCOPE: [
    { value: "client", label: "Công ty" },
    { value: "employee", label: "Từng nhân viên" },
  ],
  LOCAL_STORAGE_IGLOCAL_SETTINGS: "IGLOCAL_settings",
  LOCAL_STORAGE_PERMISSIONS: "permissions",
  CLIENT_TYPE: [
    { value: "", label: "Tất cả" },
    { value: "outsourcing", label: "outsourcing" },
    { value: "system", label: "system" },
  ],
};
