import Vue from 'vue'
import VueI18n from 'vue-i18n'
import FlagIcon from 'vue-flag-icon'
import moment from 'moment'

import {
  PhoneNumberUtil,
  PhoneNumberFormat as PNF,
} from 'google-libphonenumber'

import enLocale from 'element-ui/lib/locale/lang/en'
import viLocale from 'element-ui/lib/locale/lang/vi'
import jpLocale from 'element-ui/lib/locale/lang/ja'
import ElementLocale from 'element-ui/lib/locale'

import viValidate from 'vee-validate/dist/locale/vi.json'
import enValidate from 'vee-validate/dist/locale/en.json'
import jpValidate from 'vee-validate/dist/locale/ja.json'

import { extend, configure, localize } from 'vee-validate'
import { numeric, email, min, max_value } from 'vee-validate/dist/rules'
import * as rules from 'vee-validate/dist/rules'

const en = require('~/locales/en.json')
const vi = require('~/locales/vi.json')
const jp = require('~/locales/jp.json')

Vue.use(VueI18n)
Vue.use(FlagIcon)

const validatePhoneNumber = (value, alphaCode) => {
  if (!value || value.length === 0) {
    return false // Return false if value is empty
  }
  const phoneUtil = PhoneNumberUtil.getInstance()

  try {
    const number = phoneUtil.parse(value, alphaCode)
    console.log(phoneUtil.format(number, PNF.INTERNATIONAL))

    return phoneUtil.isValidNumber(number)
  } catch (e) {
    return false
  }
}

export default async ({ app, store, route }, inject) => {
  const messages = {
    en: {
      ...en,
      ...enLocale,
      validation: enValidate.messages,
    },
    vi: {
      ...vi,
      ...viLocale,
      validation: viValidate.messages,
    },
    ja: {
      ...jp,
      ...jpLocale,
      validation: jpValidate.messages,
    },
  }

  const i18n = new VueI18n({
    locale: 'vi',
    fallbackLocale: 'vi',
    silentTranslationWarn: true,
    messages,
    lazy: true,
  })
  ElementLocale.i18n((key, value) => i18n.t(key, value))

  Object.keys(rules).forEach((rule) => {
    extend(rule, {
      ...rules[rule], // copies rule configuration
    })
  })

  configure({
    defaultMessage: (field, values) => {
      // override the field name.
      values._field_ = i18n.t(`fields.${field}`)

      return i18n.t(`validation.${values._rule_}`, values)
    },
  })

  // Install required rule and message.
  extend('required', {
    validate(value) {
      if (Array.isArray(value)) {
        return value.length > 0
      }
      return !!value
    },
  })

  // Install email rule and message.
  extend('email', email)

  extend('numeric', numeric)

  // Install min rule and message.
  extend('min', min)

  extend('max_value', max_value)

  extend('max_value_currency', {
    params: ['max'],
    validate(value, { max }) {
      let val = String(value).replace(/,/g, "")
      return parseFloat(val) <= parseFloat(max)
    },
  })

  // Check Start Date < Block Expect and Actual
  extend('real_start_date', {
    validate(value, payload) {
      if (payload.length === 2) {
        return moment(payload[0]).isBefore(payload[1])
      }
      return true
    },
  })

  extend('before_same_start_date', {
    validate(value, payload) {
      if (payload.length === 2) {
        return moment(payload[0]).isSameOrBefore(payload[1])
      }
      return true
    },
  })

  extend('time_greater', {
    validate(value, payload) {
      return moment(value, 'HH:mm').isSameOrAfter(moment(payload[0], 'HH:mm'))
    },
  })

  extend('time_lesser', {
    validate(value, payload) {
      return moment(value, 'HH:mm').isSameOrBefore(moment(payload[0], 'HH:mm'))
    },
  })

  extend('time_between', {
    validate(value, payload) {
      const paramOne = moment(payload[0], 'HH:mm')
      let paramTwo = moment(payload[1], 'HH:mm')

      if (paramTwo.isBefore(paramOne)) {
        // Adjust paramTwo by adding 1 day if it's before paramOne
        paramTwo.add(1, 'day')
      }

      // Convert value to moment object
      const valueMoment = moment(value, 'HH:mm')

      // If value is smaller than both paramOne and paramTwo,
      // and paramOne is greater than paramTwo (across midnight)
      if (valueMoment.isBefore(paramOne) && valueMoment.isBefore(paramTwo)) {
        // Adjust value by adding 1 day
        valueMoment.add(1, 'day')
      }

      // Check if the adjusted value is between paramOne and paramTwo
      return valueMoment.isBetween(paramOne, paramTwo)
    },
  })

  extend('date_greater', {
    params: ['targetDate'],
    validate(value, { targetDate }) {
      return moment(value, 'YYYY-MM-DD').isSameOrAfter(
        moment(targetDate, 'YYYY-MM-DD')
      )
    },
  })

  extend('date_lesser', {
    params: ['targetDate'],
    validate(value, { targetDate }) {
      return moment(value, 'YYYY-MM-DD').isSameOrBefore(
        moment(targetDate, 'YYYY-MM-DD')
      )
    },
  })

  extend('date_after', {
    params: ['targetDate'],
    validate(value, { targetDate }) {
      return moment(value, 'YYYY-MM-DD').isAfter(
        moment(targetDate, 'YYYY-MM-DD')
      )
    },
  })

  extend('date_before', {
    params: ['targetDate'],
    validate(value, { targetDate }) {
      return moment(value, 'YYYY-MM-DD').isBefore(
        moment(targetDate, 'YYYY-MM-DD')
      )
    },
  })

  extend('mobile_number', {
    params: ['code'],
    validate(value, { code }) {
      return validatePhoneNumber(value, code)
    },
  })

  app.i18n = i18n

  /*
  await (new Promise((resolve, reject) => {

    Helper.gqlQuery('Translation', 'translations').then( ({data}) => {

      if( data.translations.length > 0 ) {

        store.state.lang.locales.forEach((loc) => {

          let translations = filter((f) => (f.language_id == loc), data.translations)

          let customerMessages = {}

          if( translations.length > 0 )
            translations.forEach((t) => {
              customerMessages[t.translatable_id] = t.translation
            })

          i18n.setLocaleMessage(loc, Object.assign(i18n.messages[loc], customerMessages))

        })

      }

      app.i18n = i18n;

      resolve()
    }, () => {

      app.i18n = i18n;

      reject()
    })

  }))
  */
}
