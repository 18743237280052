import Vue from "vue";

const debug = (log, des = '') => {
  if ( process.env.environment !== 'local' ) return;
  console.log(des, log);
}
const error = (log, des = '') => {
  if ( process.env.environment !== 'local' ) return;
  console.log(des, log);
}
const info = (log, des = '') => {
  if ( process.env.environment !== 'local' ) return;
  console.log(des, log);
}

// Change this config for each app
const Plugin = {};
Plugin.install = function (Vue, options) {
  Vue.prototype.$logger = {
    info,
    debug,
    error
  }
};

Vue.use(Plugin);
