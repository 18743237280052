import Vue from 'vue'
import TinyMCE from '@tinymce/tinymce-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  Button,
  Card,
  Input,
  CheckboxGroup,
  Autocomplete,
  Checkbox,
  Select,
  Option,
  Tree,
  Collapse,
  CollapseItem,
  Dialog,
  Tag,
  Tooltip,
  Popover,
  DatePicker,
  Tabs,
  TabPane,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Steps,
  Step,
  Switch,
  Menu,
  MenuItem,
  MenuItemGroup,
  Submenu,
  Radio,
  RadioGroup,
  Upload,
  OptionGroup,
  TimePicker,
  TimeSelect,
  Table,
  TableColumn,
} from 'element-ui'
import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseDropdown from '@/components/BaseDropdown.vue'
import BaseCard from '@/components/Cards/Card.vue'
import Modal from '@/components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import Badge from '@/components/Badge.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import BaseSwitch from '@/components/BaseSwitch.vue'
import BaseRadio from '@/components/Inputs/BaseRadio'
import BaseTabs from '@/components/Tabs/Tabs'
import BaseTab from '@/components/Tabs/Tab'
import BaseTable from '@/components/BaseTable'
import BaseProgress from '@/components/BaseProgress'
import BasePagination from '@/components/BasePagination'
import BaseAlert from '@/components/BaseAlert'
import BaseNav from '@/components/Navbar/BaseNav'
import BaseHeader from '@/components/BaseHeader'
import BoxHeader from '@/components/BoxHeader'
import Loading from '@/components/LoadingPanel'

//Load no data
import NoData from '@/components/common/Data/NoData'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import 'element-ui/lib/theme-chalk/index.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'jexcel/dist/jexcel.css'
import Notifications from 'vue-notification'

import {
  BModal,
  BFormGroup,
  BFormInput,
  ListGroupPlugin,
  TablePlugin,
  PaginationPlugin,
  FormCheckboxPlugin,
  BFormCheckboxGroup,
  CardPlugin,
  ImagePlugin,
  PopoverPlugin,
  CollapsePlugin
} from 'bootstrap-vue'

import DateInput from '@/components/Inputs/DateInput'

import VeeCheckbox from '@/components/Inputs/VeeCheckbox'
import VeeInput from '@/components/Inputs/VeeInput'
import VeeDate from '@/components/Inputs/VeeDate'
import VeeSelect from '@/components/Inputs/VeeSelect'
import VeeSwitch from '@/components/Inputs/VeeSwitch'
import VeeTextarea from '@/components/Inputs/VeeTextarea'
import VeeTimepicker from '@/components/Inputs/VeeTimepicker'
import LangSelect from '@/../frontend-common/components/Inputs/LangSelect.vue'
import VeeCurrency from '@/../frontend-common/components/Inputs/VeeCurrency'

import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'

Vue.config.productionTip = false

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

Vue.component(Badge.name, Badge)
Vue.component(BaseAlert.name, BaseAlert)
Vue.component(BaseButton.name, BaseButton)
Vue.component(BaseCheckbox.name, BaseCheckbox)
Vue.component(BaseHeader.name, BaseHeader)
Vue.component(BoxHeader.name, BoxHeader)
Vue.component(BaseInput.name, BaseInput)
Vue.component(BaseDropdown.name, BaseDropdown)
Vue.component(BaseNav.name, BaseNav)
Vue.component(BasePagination.name, BasePagination)
Vue.component(BaseProgress.name, BaseProgress)
Vue.component(BaseRadio.name, BaseRadio)
Vue.component(BaseSwitch.name, BaseSwitch)
Vue.component(BaseTabs.name, BaseTabs)
Vue.component(BaseTab.name, BaseTab)
Vue.component(Collapse.name, Collapse)
Vue.component(CollapseItem.name, CollapseItem)
Vue.component(BaseTable.name, BaseTable)
Vue.component(Button.name, Button)
Vue.component(BaseCard.name, BaseCard)
Vue.component(Modal.name, Modal)
Vue.component(Input.name, Input)
Vue.component(Card.name, Card)
Vue.component(Autocomplete.name, Autocomplete)
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)
Vue.component(Dialog.name, Dialog)
Vue.component(Dropdown.name, Dropdown)
Vue.component(DropdownMenu.name, DropdownMenu)
Vue.component(DropdownItem.name, DropdownItem)
Vue.component(Tree.name, Tree)
Vue.component(CheckboxGroup.name, CheckboxGroup)
Vue.component(Checkbox.name, Checkbox)
Vue.component(Checkbox.name, Checkbox)
Vue.component(Tag.name, Tag)
Vue.component(DatePicker.name, DatePicker)
Vue.component(Tabs.name, Tabs)
Vue.component(TabPane.name, TabPane)
Vue.component(Steps.name, Steps)
Vue.component(Step.name, Step)
Vue.component(Switch.name, Switch)
Vue.component(Menu.name, Menu)
Vue.component(MenuItem.name, MenuItem)
Vue.component(MenuItemGroup.name, MenuItemGroup)
Vue.component(Submenu.name, Submenu)
Vue.component(Radio.name, Radio)
Vue.component(RadioGroup.name, RadioGroup)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('Treeselect', Treeselect)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-modal', BModal)
Vue.component(VeeCheckbox.name, VeeCheckbox)
Vue.component(VeeInput.name, VeeInput)
Vue.component(VeeDate.name, VeeDate)
Vue.component(VeeSelect.name, VeeSelect)
Vue.component(VeeSwitch.name, VeeSwitch)
Vue.component(VeeTimepicker.name, VeeTimepicker)
Vue.component(VeeTextarea.name, VeeTextarea)
Vue.component(LangSelect.name, LangSelect)
Vue.component(VeeCurrency.name, VeeCurrency)
Vue.component('loading', Loading)
Vue.component(NoData.name, NoData)
Vue.component(DateInput.name, DateInput)
Vue.component(Upload.name, Upload)
Vue.component('v-select', vSelect)
Vue.component('TinyMCE', TinyMCE)
Vue.component(OptionGroup.name, OptionGroup)
Vue.component(TimePicker.name, TimePicker)
Vue.component(TimeSelect.name, TimeSelect)
Vue.component(Table.name, Table)
Vue.component(TableColumn.name, TableColumn)

Vue.use(ListGroupPlugin)
Vue.use(Tooltip)
Vue.use(Popover)
Vue.use(TablePlugin)
Vue.use(PaginationPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(CardPlugin)
Vue.use(ImagePlugin)
Vue.use(Notifications)
Vue.use(NoData)
Vue.use(PopoverPlugin)
Vue.use(CollapsePlugin)

Vue.directive('precision', {
  inserted: function (el, binding) {
    let v = binding.value ? binding.value : 0

    v = Math.round(v * 100) / 100

    el.innerHTML = v.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
  },
})
