import Helper from '@/util/helper'

export default async ({ store, req, route }) => {
  // Helper.gqlMutate('ActionLog', 'create', {
  //   input : {
  //     app_name : 'internal',
  //     screen_name : route.fullPath,
  //     action_name : 'opened',
  //   }
  // });
  const token = store.getters['auth/token']

  if (!store.getters['auth/check'] && token) {
    await store.dispatch('auth/fetchUser')
  }
}
