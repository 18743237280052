<template>
  <div>
    <!-- Dropdown header -->
    <div class="px-3 py-3">
      <div
        class="text-sm text-muted font-weight-400 m-0"
        v-html="$t('notifications.notify_count', { count: unreadTotal })"
      ></div>
    </div>

    <div v-if="notifications.length > 0">
      <!-- List group -->
      <div class="list-group list-group-flush">
        <a
          v-for="(notify, index) in notifications"
          :key="index"
          v-show="index < 3"
          @click.prevent="toNotify(notify)"
          class="list-group-item list-group-item-action"
        >
          <div class="row align-items-center">
            <div class="col ml--2">
              <div class="d-flex justify-content-between align-items-center">
                <div class="text-right text-muted">
                  <small>{{ notifyHumanizeCreated(notify.created_at) }}</small>
                </div>
              </div>
              <p class="text-sm mb-0" v-if="hasTrans(notify)" v-html="showNotify(notify)"></p>
            </div>
          </div>
        </a>
      </div>
    </div>
    <!-- View all -->
    <div class="row">
      <div class="col-6">
        <a
          @click.prevent="viewAll"
          class="dropdown-item text-center text-primary font-weight-bold py-3"
          >{{ $t("notifications.view_all") }}</a>
      </div>
      <div class="col-6">
        <a
          @click.prevent="readAll"
          class="dropdown-item text-center text-primary font-weight-bold py-3"
          >{{ $t("notifications.mark_as_read") }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import Helper from "@/util/helper";
import Cookies from "js-cookie";
import gql from "graphql-tag";
import api from "~/util/api";
import { values } from "ramda";
import {mapState} from 'vuex';

import Vue from 'vue'
import { DatePicker } from 'element-ui'
import locale from 'element-ui/lib/locale/lang/vi'
locale.el.datepicker.year = '-'; //remove vietnamese year label
locale.el.datepicker.month1 = '1';
locale.el.datepicker.month2 = '2';
locale.el.datepicker.month3 = '3';
locale.el.datepicker.month4 = '4';
locale.el.datepicker.month5 = '5';
locale.el.datepicker.month6 = '6';
locale.el.datepicker.month7 = '7';
locale.el.datepicker.month8 = '8';
locale.el.datepicker.month9 = '9';
locale.el.datepicker.month10 = '10';
locale.el.datepicker.month11 = '11';
locale.el.datepicker.month12 = '12';
Vue.use( DatePicker, { locale } );

export default {
  data() {
    return {
      notifications: [],
      limit: 3,
      intervalData: null,
      unreadTotal: 0,
      languageClass: {
          type: String,
          default: () => ('lang-en')
      },
      TYPES: [
        {
          value: 'CLIENT_REQUEST_OFF',
          label: this.$t('approve_names.client_request_off'),
        },
        {
          value: 'CLIENT_REQUEST_OT',
          label: this.$t('approve_names.client_request_ot'),
        },
        {
          value: 'CLIENT_REQUEST_OT_ASSIGNMENT',
          label: this.$t('model.worktime_register.makeup_request.type.assign_ot'),
        },
        {
          value: 'CLIENT_REQUEST_CONG_TAC',
          label: this.$t('approve_names.client_request_cong_tac'),
        },
        {
          value: 'CLIENT_REQUEST_TIMESHEET_EDIT_WORK_HOUR',
          label: this.$t('approve_names.client_request_timesheet_edit_work_hour'),
        },
        {
          value: 'CLIENT_REQUEST_CHANGED_SHIFT',
          label: this.$t('approve_names.client_request_changed_shift'),
        },
        {
          value: 'CLIENT_REQUEST_TIMESHEET_SHIFT',
          label: this.$t('approve_names.client_request_timesheet_shift'),
        },
        {
          value: 'CLIENT_REQUEST_PAYMENT',
          label: this.$t('approve_names.client_request_payment'),
        },
        {
          value: 'CLIENT_REQUEST_PAYROLL',
          label: this.$t('approve_names.client_request_payroll'),
        },
        {
          value: 'CLIENT_UPDATE_EMPLOYEE_BASIC',
          label: this.$t('approve_names.client_update_employee_basic'),
        },
        {
          value: 'CLIENT_UPDATE_EMPLOYEE_PAYROLL',
          label: this.$t('approve_names.client_update_employee_payroll'),
        },
        {
          value: 'CLIENT_UPDATE_EMPLOYEE_OTHERS',
          label: this.$t('approve_names.client_update_employee_others'),
        },
        {
          value: 'CLIENT_UPDATE_WORK_PERMIT',
          label: this.$t('approve_names.client_update_work_permit'),
        },
        {
          value: 'CLIENT_UPDATE_DEPENDENT',
          label: this.$t('approve_names.client_update_dependent'),
        },
        {
          value: 'CLIENT_REQUEST_TIMESHEET',
          label: this.$t('approve_names.client_request_timesheet'),
        },
        {
          value: 'CLIENT_REQUEST_CANCEL_OT',
          label: this.$t('approve_names.client_request_cancel_ot'),
        },
        {
          value: 'CLIENT_REQUEST_CANCEL_OFF',
          label: this.$t('approve_names.client_request_cancel_off'),
        },
        {
          value: 'CLIENT_REQUEST_CANCEL_CONG_TAC',
          label: this.$t('approve_names.client_request_cancel_cong_tac'),
        },
      ],
    };
  },
  mounted() {
    this.loadData();
    this.$nuxt.$on("otification_update", () => {
      this.loadData();
    });

    let tick = 0;
    let requesting = false;
    this.intervalData = setInterval(async () => {
      if (requesting) {
        return; // do nothing
      }
      if (this.$store.getters["auth/check"]) {
        if (tick++ === 60) {
          requesting = true;
          await this.loadData();
          tick = 0;
        }

        // TODO move this logic to somewhere else
        const expiresAt = Cookies.get("expires_at");
        const now = new Date().getTime() / 1000;
        const refreshToken = Cookies.get("refresh_token");
        const remember = Cookies.get("remember");
        if (!expiresAt || now > expiresAt - 900) {
          // before expire 15 minutes
          requesting = true;
          let queryGql = gql`
            mutation refreshToken($input: RefreshTokenInput) {
              refreshToken(input: $input) {
                access_token
                token_type
                expires_in
                refresh_token
              }
            }
          `;

          await api
            .mutate(queryGql, {
              input: {
                refresh_token: refreshToken,
              },
            })
            .then(({ data }) => data.refreshToken)
            .then((authPayload) => {
              this.$store.dispatch("auth/saveToken", {
                remember: remember,
                ...authPayload,
              });
            });
        }
      } else {
        clearInterval(this.intervalData);
      }
      requesting = false;
    }, 1000);
  },
  computed: {
      ...mapState([ "auth" ])
  },
  methods: {
    viewAll() {
      this.$router.push({ name: "thong-bao-chi-tiet" });
    },
    readAll(){
      Helper.gqlMutate("Notification", "markRead", {
      }).then(({ data }) => {
        this.unreadTotal = 0;
        this.$emit("newNotificationCount", 0);
      });
    },
    toNotify(notify) {
      Helper.gqlMutate("Notification", "markRead", {
        id: notify.id,
      }).then(({ data }) => {
        if (notify.route) this.$router.push({ path: notify.route });
      });
    },
    showNotify(notify){
      if (notify.messages.params) {
        if (notify.messages.params.type) {
          let dataType = this.TYPES.filter(function(item) {
            return item.value == notify.messages.params.type
          });
          if(dataType && dataType[0] && dataType[0]['label']){
            notify.messages.params.type = dataType[0]['label'];
          }
        }
        if (notify.messages.params.status == 'Đã duyệt' || notify.messages.params.status == 'Đã từ chối') {
          return this.$t("notify.updated_approve.messages", notify.messages.params)
        }
        if (notify.messages.params.status == 'Đã hủy') {
          return this.$t("notify.canceled_approve.messages", notify.messages.params)
        }
      }
      return this.$t(notify.messages.trans, notify.messages.params)
    },
    loadData() {
      // query approve count
      let queryGql = gql`
        query me {
          me {
            id
            assigned_approves_count
          }
        }
      `;
      api.query(queryGql).then(({ data }) => {
        this.$store.dispatch("auth/updateUserFields", {
          ...data.me,
        });
      });

      return Helper.gqlQuery(
        "Notification",
        "get",
        {
          unread: 1,
          limit: this.limit,
        },
        true
      ) // silent error
        .then(({ data }) => {

          const notifications = JSON.parse(data.notifications);
          this.notifications = values(notifications.data);
          this.unreadTotal = notifications.unread_total;
          const count = this.unreadTotal;
          this.$emit("newNotificationCount", count);
        });
    },
    notifyHumanizeCreated(created_at) {
      const moment = Helper.getMoment(this.$i18n.locale);

      const item = moment
        .duration(moment().diff(moment(created_at)))
        .humanize();

      return this.$t("notifications.created_at", { time: item });
    },
    hasTrans(notify) {
      return notify.messages ? notify.messages.trans : false;
    },
  },
};
</script>
<style scoped>
.vue-avatar {
  box-sizing: content-box;
}
.avatar.rounded-circle {
  width: 32px;
  height: 32px;
}
</style>
