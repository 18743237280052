<template>
    <div class="px-3">
        <!--<div v-model="$i18n.locale" class="pb-2" v-for="entry in languages" :key="entry.title" @click="changeLocale(entry.language)">-->
        <!--<flag :iso="entry.flag" v-bind:squared=false class="shadow" />-->
        <!--<span class="font-size-14">{{entry.title}}</span>-->
        <!--</div>-->
        <el-dropdown @command="changeLang">
            <div class="text-black" style='cursor: pointer;'>
                <flag :iso="selectedLang.iso" class="font-size-16 shadow mr-1" />
            </div>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command='en'>
                    <flag iso="us" class="font-size-16 shadow mr-1" />
                    English
                </el-dropdown-item>
                <el-dropdown-item command='vi'>
                    <flag iso="vn" class="font-size-16 shadow mr-1" />
                    Tiếng việt
                </el-dropdown-item>
                <el-dropdown-item command='ja'>
                    <flag iso="jp" class="font-size-16 shadow mr-1" />
                    日本語
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
import flag from "vue-flag-icon"
import Helper from "@/util/helper"

export default {
  name: "SwitchLocale",
  computed: {
    selectedLang() {
      const lang = this.$store.getters['lang/locale']
      this.$emit('lang', lang)
      return lang == 'vi' ? {iso: 'vn', name: 'Tiếng Việt'} :
             lang == 'en' ? {iso: 'us', name: 'English'} :
             lang == 'ja' ? {iso: 'jp', name: '日本語'} :
             lang
    }
  },
  methods: {
    changeLang (lang) {
      //mutate 'locale' in store
      // this.$store.commit('lang/SET_LOCALE', lang)
      Helper.gqlMutate('Auth', 'setPreferences', {
        id: this.$store.getters['auth/user'].id,
        preferedLanguage: lang
      }).then( () => {
        this.$emit('lang', lang)
        window.location.reload()
      })
    }
  }
}
</script>

<style scoped>

</style>
