<template></template>

<script>

export default {
  name: "CheckConnection",
  computed: {},
  mounted() {
    const loadEnLang = () => import('../../frontend-common/plugins/offline-language-english.css');
    const loadJaLang = () => import('../../frontend-common/plugins/offline-language-japanese.css');
    const loadViLang = () => import('../../frontend-common/plugins/offline-language-vietnamese.css');
    const user = this.$store.getters["auth/user"];
    const lang = user.prefered_language;

    if (lang === 'en') {
      loadEnLang();
    } else if (lang === 'ja') {
      loadJaLang();
    } else {
      loadViLang();
    }
    this.initial();
  },
  methods: {
    initial() {
      var run = function(){
        Offline.check();
      }
      setInterval(run, 5000);
    }
  },
};
</script>

<style scoped></style>
