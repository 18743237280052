import Cookies from 'js-cookie'
import Vue from 'vue'
import Constant from '@/plugins/Constant'
import { cookieFromRequest } from '~/util'
import Settings from '@/dao/Settings'

export const actions = {
  nuxtServerInit ({ commit }, { req }) {
    const token = cookieFromRequest(req, 'token')

    if (token) {
      commit('auth/SET_TOKEN', token)
    }

    const locale = cookieFromRequest(req, 'locale')
    if (locale) {
      commit('lang/SET_LOCALE', locale)
    }
  },

  nuxtClientInit ({ commit, dispatch }) {
    const token = Cookies.get('token')

    if (token) {
      commit('auth/SET_TOKEN', token)
    }

    const locale = Cookies.get('locale')
    if (locale) {
      commit('lang/SET_LOCALE', locale)
    }

    // const settings = localStorage.getItem(Constant.LOCAL_STORAGE_IGLOCAL_SETTINGS)
    // if (settings) {
    //   const deserializedSettings = JSON.parse(settings)
    //   commit('settings/SET_SETTINGS', deserializedSettings)
    // } else {
    //   Settings.get().then((settings) => {
    //     commit('settings/SET_SETTINGS', settings)
    //     Vue.localStorage.set(Constant.LOCAL_STORAGE_IGLOCAL_SETTINGS, JSON.stringify(settings))
    //   })
    // }
  }
}

export const strict = false

