import Cookies from "js-cookie";
import Constant from "@/plugins/Constant";
import User from "@/dao/User";
import Helper from "@/util/helper";

// state
export const state = () => ({
  user: null,
  token: null,
  iglocalEmployees: [],
  counter: 0,
  provinceData: {},
});

// getters
export const getters = {
  user: (state) => state.user,
  token: (state) => state.token,
  check: (state) => state.user !== null,
  permissions: (state) => state.permissions,
  provinceData: (state) => state.provinceData,
};

// mutations
export const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },

  FETCH_USER_SUCCESS(state, { me }) {
    state.user = me;

    if (state.user.iGlocalEmployee) {
      state.user.role = state.user.iGlocalEmployee.role;

      state.user.groups = [{ id: "0", label: "Default" }];
    }

    if (state.user && !state.user.working_hours) {
      state.user.working_hours = 8;
    }

    if (state.user && !state.user.system_variables) {
      state.user.system_variables = Constant.SYSTEM_VARIABLES;
    }

    if (me.permissions) {
      const p = JSON.parse(me.permissions);

      state.permissions = p.map((v) => v.name);
    }

    if (typeof rg4js === "function") {
      rg4js("setUser", {
        identifier: me.id,
        isAnonymous: false,
        email: me.email,
        fullName: me.name + " (INTERNAL)",
      });
    }
  },

  FETCH_USER_FAILURE(state) {
    state.token = null;
  },

  LOGOUT(state) {
    state.user = null;
    state.token = null;
  },

  UPDATE_USER(state, { user }) {
    state.user = user;
  },

  updateUserFields(state, user) {
    if (!state.user) {
      state.user = {};
    }
    Object.assign(state.user, user);
  },

  updateIglocalEmployees(state, employees) {
    state.iglocalEmployees = employees;
  },

  UPDATE_COUNTER(state, counter) {
    state.counter = counter;
  },

  UPDATE_PROVINCE_DATA(state, provinces) {
    state.provinceData = provinces;
  },
};

// actions
export const actions = {
  saveToken(
    { commit, dispatch },
    { access_token, refresh_token, expires_in, remember }
  ) {
    commit("SET_TOKEN", access_token);
    let options = {};
    if (remember) {
      options = { expires: 365 };
    }
    Cookies.set("token", access_token, options);
    Cookies.set("refresh_token", refresh_token, options);
    Cookies.set(
      "expires_at",
      new Date().getTime() / 1000 + expires_in,
      options
    );
    Cookies.set("remember", remember, options);
  },

  async fetchUser({ commit }) {
    try {
      const { data } = await User.me();

      commit("FETCH_USER_SUCCESS", data);
      commit("lang/SET_LOCALE", data.me.prefered_language, { root: true });
    } catch (e) {
      Cookies.remove("token");
      commit("FETCH_USER_FAILURE");
    }
  },

  fetchIglocalEmployees({ commit }) {
    return new Promise((resolve, reject) => {
      Helper.gqlQuery("IglocalEmployee", "getAll", {
        orderBy: [{ field: "NAME", order: "ASC" }],
      }).then(({ data }) => {
        const items = data.iglocalEmployeesAll;
        console.log("@iglocalEmployees", items);
        commit("updateIglocalEmployees", items);
        resolve();
      });
    });
  },

  updateUser({ commit }, payload) {
    commit("UPDATE_USER", payload);
  },

  updateUserFields({ commit }, payload) {
    commit("updateUserFields", payload);
  },

  async logout({ commit }) {
    try {
      await User.logout();
    } catch (e) {}
    Cookies.remove("token");
    commit("LOGOUT");
  },

  updateCounter({ commit }, payload) {
    commit("UPDATE_COUNTER", payload);
  },

  updateProvinceData({ commit }, payload) {
    commit("UPDATE_PROVINCE_DATA", payload);
  },
};
