import Vue from "vue";
// import moment from "moment"
import moment from "moment-timezone";
import numeral from "numeral";

/**
 * Datetime formating
 */
const MODE_DATE = "date";
const MODE_TIME = "time";
const MODE_DATETIME = "datetime";
const MODE_LONGDATETIME = "longdatetime";
Vue.prototype.$dateTimeFormat = function (datetime, mode) {
  if (!datetime) return "";

  let user = this.$store.getters["auth/user"];
  let timezone = user.timezone_name;
  const nomarlizedMode = String(mode).toLowerCase();
  const format =
    nomarlizedMode === MODE_DATE
      ? this.$t("format.date")
      : nomarlizedMode === MODE_TIME
      ? "HH:mm"
      : nomarlizedMode === MODE_LONGDATETIME
      ? "DD/MM/YYYY HH:mm"
      : this.$t("format.datetime");

  if (timezone) {
    return moment.utc(datetime).tz(timezone).format(format);
  }

  return moment.utc(datetime).local().format(format);
};

/**
 * Timezone to UTC
 */
Vue.prototype.$dateTimeToUTC = function (datetime, timezone, mode) {
  const nomarlizedMode = String(mode).toLowerCase();
  const format =
    nomarlizedMode == MODE_DATE
      ? this.$t("format.date")
      : nomarlizedMode == MODE_TIME
      ? this.$t("format.time")
      : this.$t("format.datetime");

  if (timezone) {
    return moment.tz(datetime, timezone).utc().format(format);
  }

  return moment.utc(datetime).local().format(format);
};

/**
 * Return formated currency
 * @param {*} value
 * @returns
 */
Vue.prototype.$currencyFormat = function (value, format) {
  if (isNaN(value) || String(value).length === 0) {
    return value;
  }

  if(!format) {
    format = "0,0[.]0[0000000000]"
  }

  return numeral(parseFloat(value)).format(format);
};


/**
 * Return striped HTML
 * @param {*} value
 * @returns
 */
Vue.prototype.$stripHTML = function (value) {
  const regex = /(<([^>]+)>)/gi;
  return value.replace(regex, "");
};

Vue.prototype.$formatHTML = function (value) {
  return value.replace(/\n/g, "<br />");
}

