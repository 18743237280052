import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

let backendUrl = process.env.graphqlUrl;
let sessionOver = false;

const messages = {
  "error.authentication": {
    en: "Your session is expired. Please login again to continue.",
    ja: "セッションが切れました。再度ログインしてください。",
    vi: "Phiên làm việc đã hết hạn. Xin vui lòng đăng nhập lại để tiếp tục sử dụng.",
  },
  "error.network": {
    en: "Internet connection error",
    ja: "インターネット接続エラー",
    vi: "Lỗi kết nối",
  },
  "error.network_detail": {
    en: "Could not connect to server. Please check your internet connection and try again!",
    ja: "サーバーに接続できませんでした。再度インターネット接続を確認してください。",
    vi: "Hiện tại không thể kết nối đến máy chủ. Xin vui lòng kiểm tra lại kết nối mạng và thử lại sau.",
  },
  "error.internal": {
    en: "Please contact administrator for more information.",
    ja: "詳細はシステム管理者までお問合せください。",
    vi: "Liên hệ quản trị hệ thống để biết thêm chi tiết.",
  },
  "error.internal_detail": {
    en: "Error happened during the processing. Please try later.",
    ja: "処理中にエラーが発生しました。しばらくしてからもう一度お試しください。",
    vi: "Đã xảy ra lỗi trong quá trình xử lý. Xin vui lòng thử lại sau.",
  },
  reload: {
    en: "Reload page",
    vi: "Tải lại trang",
    ja: "ページの再読み込み",
  },
  "error.permission": {
    en: "You don't have permission for this action.",
    vi: "Bạn không đủ quyền để thực hiện thao tác này",
    ja: "この操作を実行する権限はありません。",
  },
};

const lang = document.documentElement.lang || "en";

const __ = (key) => {
  if (!messages[key] || !messages[key][lang]) {
    throw new Error("Api.js: Message is not configured");
  }
  return messages[key][lang];
};

const showNetworkError = () => {
  Swal.fire({
    title: __("error.network"),
    text: __("error.network_detail"),
    icon: "error",
    confirmButtonText: __("reload"),
  }).then((result) => {
    if (result.isConfirmed) {
      location.reload();
    }
  });
};

const createBatchLink = () => {
  return new BatchHttpLink({
    uri: backendUrl,
    batchMax: 1000,
    batchInterval: 200,
  });
};

// if (
//   !process.env.DISABLE_DYNAMIC_JSON_CONFIG ||
//   process.env.NODE_ENV !== "production"
// ) {
//   fetch("/app.json")
//     .then((response) => {
//       if (response.status >= 200 && response.status < 300) {
//         return response;
//       }
//       throw new Error("/app.json is not defined");
//     })
//     .then((res) => res.json())
//     .then((config) => {
//       backendUrl = config.graphql_url;
//     })
//     .catch((err) => {
//       /* silent is golden */
//     });
// }

const api = {
  batchLink: null,
  backendUrl: process.env.graphqlUrl,
  getToken: () => Cookies.get("token"),
  httpLink: async () => {
    if (!api.batchLink) {
      api.batchLink = createBatchLink();
      // everything failed, fall back to old method
      // return createHttpLink({
      //   uri: backendUrl
      // })
    }
    return api.batchLink;
  },
  uploadLink: async () => {
    return createUploadLink({
      uri: backendUrl,
    });
  },
  authLink: function () {
    const token = this.getToken();
    return setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });
  },
  errorHandleMiddleware: function (silent) {
    return onError((error) => {
      // if silent = true, supress all error dialog
      if (silent) {
        // do nothing
        return;
      }

      /**
       * RaygunJS processing
       */
      if (error.response && Array.isArray(error.response.errors)) {
        const message =
          error.graphQLErrors[0].debugMessage || error.graphQLErrors[0].message;

        // Only report error that is unknown (ERR0001) to backend
        const withoutIdentifiedCode = !!error.graphQLErrors.find((v) => {
          if (
              v.extensions &&
              v.extensions.category === "custom_exception" &&
              (!v.extensions.code || v.extensions.code === "ERR0001")
          ) {
            return true;
          }
          return false;
        });

        if (message && process.env.raygunEnabled && withoutIdentifiedCode) {
          rg4js("send", {
            error: new Error(message),
            customData: error,
            tags: ["frontend", "graphql_error"],
          });
        }
      }

      if (error.networkError) {
        switch (error.networkError.name) {
          case "ServerParseError":
            Swal.fire({
              title: "Lỗi server",
              text: "Hệ thống hiện tại đang được bảo trì. Xin vui lòng thử lại sau.",
              icon: "error",
              confirmButtonText: "Đóng",
            });
            break;
          default:
            showNetworkError();
            break;
        }

        if (error.networkError.message === "Failed to fetch") {
          showNetworkError();
        }

        return;
      }

      if (!window.navigator.onLine) {
        Swal.fire({
          title: "No Internet",
          text: "Please! Check your internet connection",
          icon: "info",
          confirmButtonText: "Reload",
        }).then((result) => {
          if (result.isConfirmed) {
            location.reload();
          }
        });
        return;
      }

      if (error.graphQLErrors) {
        if (error.graphQLErrors[0].extensions.category === "validation") {
          const arrMessage = Object.values(error.graphQLErrors[0].extensions.validation)
          const flatArray = [].concat(...arrMessage)

          Swal.fire({
            title: flatArray.join("</br>"),
            icon: "error",
            confirmButtonText: "OK",
          });
          return;
        }
        if (error.graphQLErrors[0].message === "Unauthenticated.") {
          if (!sessionOver) {
            Swal.fire({
              title: "",
              text: __("error.authentication"),
              icon: "info",
              confirmButtonText: "OK",
            }).then(() => {
              sessionOver = false;
              Cookies.remove("token");
              Cookies.remove("refresh_token");
              Cookies.remove("remember");
              window.location.href = "/dang-nhap";
            });
            sessionOver = true;
          }
          return;
        } else if (
          error.graphQLErrors[0].message === "InvalidPermission" ||
          error.graphQLErrors[0].message ===
            "You are not authorized to access." ||
          error.graphQLErrors[0].message.indexOf(
            "You are not authorized to access"
          ) !== -1
        ) {
          Swal.fire({
            title: this.$t('no_permissions'),
            icon: "info",
            confirmButtonText: "OK",
          });
          return;
        }
        let messages = [];
        error.graphQLErrors.map((v) => {
          if (
            v.extensions &&
            v.extensions.category === "custom_exception" &&
            v.extensions.reason
          ) {
            messages.push(v.extensions.reason);
          }
        });
        if (messages.length) {
          Swal.fire({
            text: messages.join("</br>"),
            icon: "info",
            confirmButtonText: "OK",
          });
          return;
        }
      }
      // fallback
      Swal.fire({
        title: __("error.internal"),
        text: __("error.internal_detail"),
        icon: "error",
        confirmButtonText: "Đóng",
      });
    });
  },
  query: function (queryGql, variables, silent) {
    return this.httpLink().then((httpLink) => {
      const client = new ApolloClient({
        link: this.authLink()
          .concat(this.errorHandleMiddleware(silent))
          .concat(httpLink),
        cache: new InMemoryCache(),
      });
      return client.query({
        query: queryGql,
        variables: variables,
      });
    });
  },
  singleQuery: function (queryGql, variables, silent) {
    const httpLink = new HttpLink({
      uri: this.backendUrl,
    });

    const client = new ApolloClient({
      link: this.authLink()
        .concat(this.errorHandleMiddleware(silent))
        .concat(httpLink),
      cache: new InMemoryCache(),
    });

    return client.query({
      query: queryGql,
      variables: variables,
    });
  },
  mutate: function (queryGql, variables, silent) {
    return this.httpLink().then((httpLink) => {
      const client = new ApolloClient({
        link: this.authLink()
          .concat(this.errorHandleMiddleware(silent))
          .concat(httpLink),
        cache: new InMemoryCache(),
      });

      return client.mutate({
        mutation: queryGql,
        variables: variables,
      });
    });
  },
  singleMutate: function (queryGql, variables, silent) {
    const httpLink = new HttpLink({
      uri: this.backendUrl,
    });

    const client = new ApolloClient({
      link: this.authLink()
        .concat(this.errorHandleMiddleware(silent))
        .concat(httpLink),
      cache: new InMemoryCache(),
    });

    return client.mutate({
      mutation: queryGql,
      variables: variables,
    });
  },
  upload: function (queryGql, variables, silent) {
    return this.uploadLink().then((httpLink) => {
      const client = new ApolloClient({
        link: this.authLink()
          .concat(this.errorHandleMiddleware(silent))
          .concat(httpLink),
        cache: new InMemoryCache(),
      });
      return client.mutate({
        mutation: queryGql,
        variables: variables,
      });
    });
  },
};

export default api;
