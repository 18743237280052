<template>
  <div>
    <ValidationProvider
      :vid="name"
      :name="name"
      :rules="rules"
      v-slot="{ errors }"
      :tag="templateTag"
    >
      <div class="form-group" :style="noMarginBottom ? 'margin-bottom: 0;' : ''">
        <label v-if="label" class="form-control-label"
          ><strong v-if="required" class="text-danger">*</strong>
          {{ label }}</label
        >
        <div class="has-label">
          <el-time-picker
            :is-range="isRange"
            :range-separator="$t('range_time')"
            v-model="valueData"
            :value-format="valueFormat"
            :placeholder="placeholder"
            :disabled="disabled"
            :format="format"
            :clearable="clearable"
            v-bind="$attrs"
          >
          </el-time-picker>
        </div>
        <div v-if="errors[0]" class="mt-2">
          <small class="text-danger">{{ errors[0] }}</small>
        </div>
      </div>
    </ValidationProvider>
  </div>
</template>
<script>
export default {
  name: 'VeeTimepicker',
  props: {
    value: {
      type: [Array, String],
      default: () => [],
    },
    templateTag: {
      type: String,
      default: () => 'span',
    },
    name: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    rules: {
      type: String,
      default: () => 'required',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    required: {
      type: Boolean,
      default: () => true,
    },
    isRange: {
      type: Boolean,
      default: () => false,
    },
    format: {
      type: String,
      default: () => 'dd/MM/yyyy',
    },
    valueFormat: {
      type: String,
      default: () => 'HH:MM',
    },
    type: {
      type: String,
      default: () => 'date',
    },
    clearable: {
      type: Boolean,
      default: () => false,
    },
    noMarginBottom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueData: this.value,
    }
  },
  watch: {
    value() {
      this.valueData = this.value
      this.$emit('onChange', this.value);
    },
    valueData(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss">
.el-date-editor .el-range-separator {
  width: auto !important;
  color: #606266 !important;
}
.el-date-editor .el-range-input,
.el-date-editor .el-range-separator {
    text-align: left;
}
</style>
