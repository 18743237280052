import Vue from "vue";

import delayed from 'delayed'

/**
 * Return formated currency
 * @param {*} value
 * @returns
 */
Vue.prototype.$getOptions = function(optionsName) {
    switch (optionsName) {
        case "employeeSalaryStates":
            return [
                {label: this.$t('model.employees.working'), value: 'đang làm việc'},
                {label: this.$t('model.clients.unpaid_leave'), value: 'nghỉ không lương'},
                {label: this.$t('model.employees.state.nghi_thai_san'), value: 'nghỉ thai sản'},
                {label: this.$t('model.employees.state.nghi_viec'), value: 'nghỉ việc'},
            ];

        case "symtemVariable":
            return [
                {label: this.$t('model.salary_test.working_hours_per_month'), value: 'Số giờ làm việc cơ bản trong tháng'},
                {label: this.$t('model.salary_test.paid_leave'), value: 'Số giờ nghỉ phép có hưởng lương'},
                {label: this.$t('model.salary_test.unpaid_leave'), value: 'Số giờ nghỉ phép không hưởng lương'},
                {label: this.$t('model.salary_test.overtime_weekday'), value: 'Số giờ làm thêm các ngày trong tuần'},
                {label: this.$t('model.salary_test.overtime_weekend'), value: 'Số giờ làm thêm các ngày cuối tuần'},
                {label: this.$t('model.salary_test.overtime_holiday'), value: 'Số giờ làm thêm ngày lễ'},
                {label: this.$t('model.salary_test.hour_business_travel'), value: 'Số giờ đi công tác'},
                {label: this.$t('model.salary_test.taxable_object'), value: 'Đối tượng tính thuế'},
                {label: this.$t('model.salary_test.subject_paying'), value: 'Đối tượng đóng BHXH, BHYT, BHTN'},
                {label: this.$t('model.salary_test.social_insurance_premium'), value: 'Mức đóng bảo hiểm xã hội'},
                {label: this.$t('model.salary_test.standard_working_hours'), value: 'Số giờ làm việc tiêu chuẩn'},
                {label: this.$t('model.salary_test.total_overtime_hour_at_night'), value: 'Tổng số giờ tăng ca ban đêm'},
                {label: this.$t('model.salary_test.night_hour_weekday'), value: 'Số giờ tăng ca ban đêm vào ngày thường'},
                {label: this.$t('model.salary_test.night_hour_weekend'), value: 'Số giờ tăng ca ban đêm vào ngày cuối tuần'},
                {label: this.$t('model.salary_test.night_hour_holiday'), value: 'Số giờ tăng ca ban đêm vào ngày Lễ/Tết'},
                {label: this.$t('model.salary_test.total_working_time_at_night'), value: 'Tổng thời gian làm việc vào ban đêm'},
                {label: this.$t('model.salary_test.total_overtime_in_year'), value: 'Tổng thời gian tăng ca trong năm (tính đến thời điểm hiện tại)'},
                {label: this.$t('model.salary_test.start_time_selected'), value: 'Thời gian bắt đầu tính lương đã chọn khi tạo bảng lương'},
                {label: this.$t('model.salary_test.end_time_selected'), value: 'Thời gian kết thúc tính lương đã chọn khi tạo bảng lương'},
                {label: this.$t('model.salary_test.currency_base_salary'), value: 'Loại tiền tệ của mức lương cơ bản'},
                {label: this.$t('model.salary_test.number_of_leave_in_year'), value: 'Số ngày phép còn lại trong năm'},
                {label: this.$t('model.salary_test.working_status'), value: 'Tình trạng làm việc'},,
                {label: this.$t('model.salary_test.total_allowance_deduction'), value: 'Tổng phụ cấp / khấu trừ'},
                {label: this.$t('model.salary_test.total_number_of_employee'), value: 'Tổng số nhân viên của department'},
            ];
    }
};

Vue.prototype.$debounce500ms = (func) => {
    return delayed.debounce(
        () => func,
        500
    )
}
