import Helper from '@/util/helper'

const me = () => 
	Helper.gqlQuery('Auth', 'me')

const logout = () => 
	Helper.gqlMutate('Auth', 'logout')

export default {
	me,
	logout
}
