<template>
  <div>
    <img src="/img/theme/no_data.png" alt="">
    <div class="">{{ $t('no_data') }}</div>
  </div>
</template>

<script>
export default {
  name: "NoData"
}
</script>
