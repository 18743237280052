// state
import Vue from "vue"

export const state = () => ({
  settings: {}
})

// getters
export const getters = {
  // settings: state => state.settings
}

// mutations
export const mutations = {
  SET_SETTINGS (state, settings) {
    const getSettings = typeof settings === "string" ? JSON.parse(settings) : settings;
    state.settings = getSettings
  }
}
