<template>
<ValidationProvider :rules="rules" v-slot="{ errors }">
  <VeeSelect :options="options" v-model="valueData" :label="label" :required="required"/>
  <div v-show="errors[0]"><small class="text-danger mt-2" id="error">{{$t('errors.fields.' + name)}}</small></div>
  <div v-show="customError"><small class="text-danger mt-2" id="error">{{customError}}</small></div>
  </ValidationProvider>
</template>

<script>
export default {
  name: "LangSelect",
  props: {
    value: {
      type:  String,
      default: () => ('')
    },
    label: {
      type:  String,
      default: () => ('')
    },
    name: {
      type:  String,
      default: () => ('')
    },
    disabled: {
      type:  Boolean,
      default: () => (false)
    },
    required: {
      type:  Boolean,
      default: () => (false)
    },
  },
  data() {
    return {
      valueData: this.value,
      options: [
          {label: 'English', value: 'en'},
          {label: 'Japan', value: 'ja'},
          {label: 'Việt Nam', value: 'vi'},
      ],
      date: '',
      time: '',
      localTriggerChanged: false,
      showPicker: false,
      customError: ''
    }
  },
  computed: {
    rules() {
      return this.required ? 'required' : ''
    },
  },
  watch: {
    valueData(v){
      this.$emit('input', v)
    },
  },
}
</script>