<template>
  <ValidationProvider
    :vid="name"
    :name="name"
    :rules="rules"
    v-slot="{ errors }"
    :tag="templateTag"
  >
    <BaseInput
      v-model="valueData"
      :label="label"
      :labelClasses="labelClasses"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      :error="errors[0]"
      :valueMin="valueMin"
      :valueMax="valueMax"
      :appendRaw="appendRaw"
      :prependRaw="prependRaw"
      :type="type"
      v-bind="$attrs"
      :is-break="isBreakError"
      :formGroupClasses="formGroupClasses"
      :noMarginBottom="noMarginBottom"
      @keypress="allowOnlyNumber"
      @click="() => $emit('click')"
    >
      <template v-for="(_, slot) in $slots" v-slot:[slot]>
        <slot :name="slot"></slot>
      </template>
    </BaseInput>
  </ValidationProvider>
</template>
<script>
export default {
  name: 'VeeInput',
  props: {
    value: {
      type: [String, Number],
      default: () => '',
    },
    name: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    templateTag: {
      type: String,
      default: () => 'div',
    },
    type: {
      type: String,
      default: 'text',
    },
    valueMin: {
      type: [String, Number],
      default: 0,
    },
    valueMax: {
      type: [String, Number],
      default: 99,
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    rules: {
      type: String | Array | Object,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    isBreakError: {
      type: Boolean,
      default: () => false,
    },
    appendRaw: {
      type: Boolean,
      default: false,
    },
    prependRaw: {
      type: Boolean,
      default: false,
    },
    formGroupClasses: {
      type: String,
      description: 'Input label css classes',
      default: '',
    },
    isCheckUsername: {
      type: Boolean,
      default: false,
    },
    labelClasses: {
      type: String,
      default: () => '',
    },
    noMarginBottom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueData: this.value,
    }
  },
  watch: {
    value() {
      this.valueData = this.value
    },
    valueData(value) {
      this.$emit('input', value)
    },
  },
  methods: {
    allowOnlyNumber($event) {
      if (this.type === 'number') {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which
        if (
          keyCode > 31 &&
          (keyCode < 48 || keyCode > 57) &&
          keyCode !== 46 &&
          keyCode !== 13
        ) {
          let regex = /^[0-9]*\.?[0-9]*$/
          if (!$event.target.value.toString().match(regex)) {
            $event.preventDefault()
          }
          $event.preventDefault()
        }
      }
      if (this.isCheckUsername) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which
        if (keyCode == 32) {
          $event.preventDefault()
        }
      }
    },
  },
}
</script>
