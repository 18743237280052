import moment from "moment";
import {remove} from "ramda";
import Swal from "sweetalert2";
import api from "@/util/api";

export function getErrorCodeFromResponse(errorResponse) {
    try {
        if (errorResponse.graphQLErrors && errorResponse.graphQLErrors[0]) {
            return errorResponse.graphQLErrors[0].extensions.code;
        }
    } catch (e) {
        return ""
    }
}

export default {

    gqlQuery(schema, action, variables, silent) {
        return api.query(this.schemas[schema][action], variables, silent);
    },

    gqlMutate(schema, action, variables, silent) {
        return api.mutate(this.schemas[schema][action], variables, silent);
    },

    gqlUpload(schema, action, variables) {
        return api.upload(schemas[schema][action], variables);
    },

    convertToSlug(title) {
        let slug = title.toLowerCase();

        // Đổi ký tự có dấu thành không dấu
        slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a')
        slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e')
        slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i')
        slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o')
        slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u')
        slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y')
        slug = slug.replace(/đ/gi, 'd')
        // Xóa các ký tự đặt biệt
        slug = slug.replace(/[`~!@#|$%^&*()+=,./?><'":;_]/gi, '')
        // Đổi khoảng trắng thành ký tự gạch ngang
        slug = slug.replace(/ /gi, '_')
        // Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
        // Phòng trường hợp người nhập vào quá nhiều ký tự trắng
        slug = slug.replace(/-----/gi, '_')
        slug = slug.replace(/----/gi, '_')
        slug = slug.replace(/---/gi, '_')
        slug = slug.replace(/--/gi, '_')
        // Xóa các ký tự gạch ngang ở đầu và cuối
        slug = '@' + slug + '@'
        slug = slug.replace(/@-|-@|@/gi, '')

        return slug
    },

    formatUsernameForDisplay(username) {
        const usernames = username.split('_')

        if (usernames.length > 0) {
            const newUsername = remove(0, 1, usernames)

            return newUsername.join('_')
        }

        return username
    },

    getClientCodeFromUsername(username) {
        const usernames = username.split('_')

        if (usernames.length > 0) {
            return usernames[0]
        }

        return ''
    },

    getDuration(fromDate, toDate) {
        const startTime = moment(fromDate)
        const endTime = moment(toDate)
        const duration = moment.duration(endTime.diff(startTime))
        return duration.asHours()
    },

    formatDateTime(datetime, format) {
        format = format || 'DD/MM/YYYY HH:mm'

        return moment(datetime).format(format)
    },

    getEmployeeOneLineName(clientEmployee) {
        return '[' + clientEmployee.code + '] ' + clientEmployee.full_name
    },

    toPrecision(value) {
        const v = value || 0
        return Math.round(v * 100) / 100
    },

    formatMoney(value) {
        const v = Math.round(value)
        return new Intl.NumberFormat('vn-VI').format(v)
    },

    getMoment(locale) {
        moment.suppressDeprecationWarnings = true
        moment.locale(locale)
        return moment
    },

    downloadFileFromJson(json) {
        const rs = JSON.parse(json)
        const a = document.createElement('a')
        a.href = rs.file
        a.download = rs.name
        document.body.appendChild(a)
        a.click()
        a.remove()
    },

    handleError(err) {
        if (
            err.graphQLErrors &&
            err.graphQLErrors.length &&
            err.graphQLErrors[0].extensions &&
            err.graphQLErrors[0].extensions.validation
        ) {
            const validation = err.graphQLErrors[0].extensions.validation;
            if (
                validation.username &&
                validation.username.indexOf("validation.username_exists") !== -1
            ) {
                // Lỗi username trùng
                Swal.fire({
                    title: this.$t("model.notifications.creating_employee_failed"),
                    text: this.$t("model.notifications.duplicate_username"),
                    icon: "error",
                    confirmButtonText: "OK"
                }).then(confirm => {
                    this.loading = false;
                });
            } else {
                // Lỗi chung chung
                Swal.fire({
                    title: this.$t("model.notifications.creating_employee_failed"),
                    text: this.$t("model.notifications.please_check_form"),
                    icon: "error",
                    confirmButtonText: "OK"
                }).then(confirm => {
                    this.loading = false;
                    this.$refs.formData.setErrors(
                        err.graphQLErrors[0].extensions.validation
                    );
                });
            }
        }
    },

    generateUUID() {
        const S4 = () =>
            Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1)
        const guid = `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`

        return guid.toLowerCase()
    },

    groupBy(list, props) {
        return list.reduce((a, b) => {
            ;(a[b[props]] = a[b[props]] || []).push(b)
            return a
        }, {})
    },

    sessionTimeout() {
        return window.localStorage.getItem("session-timeout");
    },

    setSessionTimeout(minute) {
        minute = parseInt(minute);
        if (minute && !isNaN(minute)) {
            window.localStorage.setItem("session-timeout", minute);
        } else {
            window.localStorage.removeItem("session-timeout");
        }
    },
};
