<template>
  <ValidationProvider
    :vid="name"
    :name="name"
    :rules="rules"
    v-slot="{ errors }"
  >
    <div class="form-group">
      <label v-if="label" class="form-control-label">
        <strong v-if="required" class="text-danger">*</strong>
        {{ label }}
      </label>
      <div class="has-label" :style="isBreak ? 'flex-basis: 55%;' : ''">
        <input
          :type="type"
          v-model="valueData"
          :disabled="disabled"
          :placeholder="placeholder"
          class="form-control"
          @focus="() => (isFocus = true)"
          @blur="validate"
          ref="inputEl"
        />
      </div>
      <div v-if="errors[0]" class="mt-2">
        <small class="text-danger">{{ errors[0] }}</small>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
export default {
  name: "VeeCurrency",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [String, Number],
      required: true,
      default: () => "",
    },
    name: {
      type: String,
      default: () => "",
    },
    label: {
      type: String,
      default: () => "",
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    rules: {
      type: String,
      default: () => "",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      default: () => "text",
    },
    isBreak: {
      type: Boolean,
      default: () => false,
    },
    format: {
      type: String,
      default: () => "0,0[.]0[0000000000]",
    },
    isCustom: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isFocus: false,
    };
  },
  computed: {
    valueData: {
      get() {
        let hasDot = this.value
          ? String(this.value).charAt(this.value.length - 1) == "."
          : "";

        if (hasDot) {
          return this.value;
        }

        if (this.isFocus) {
          return this.value;
        } else {
          return this.$currencyFormat(this.value, this.format);
        }
      },
      set(value) {
        // strip thousand separators
        // TODO handle different intl formats
        let formattedValue = String(value).replace(/,/g, "");

        // Check if the value is negative
        let numericValue = parseFloat(formattedValue);
        if (!this.isCustom) {
          if (isNaN(numericValue) || numericValue < 0) {
            formattedValue = "0";
          } else {
            formattedValue = String(numericValue);
          }
        }
        this.$emit("change", formattedValue);
      },
    },
  },
  methods: {
    validate(event) {
      if (String(this.valueData).charAt(this.valueData.length - 1) == ".")
        this.valueData = parseFloat(this.valueData);

      this.isFocus = false;
    },
  },
};
</script>
