<template>
<div>
    <ValidationProvider :vid="name" :name="name" :rules="rules" v-slot="{ errors }">
        <div class="form-group">
            <label v-if="label" class="form-control-label"><strong v-if="required" class="text-danger">*</strong> {{label}}</label>
            <div class="has-label">

                <textarea v-model="valueData" 
                    :placeholder="placeholder" 
                    :required="required" 
                    :disabled="disabled" :error="errors[0]" v-bind="$attrs"
                    class="form-control" @focus="handleFocus"></textarea>
                <div v-if="errors[0]" class="mt-2"><small class="text-danger">{{errors[0]}}</small></div>

            </div>
        </div>
    </ValidationProvider>
</div>
</template>
<script>

export default {
    name: 'VeeTextarea',
    props: {
        value: {
            type: [String, Number],
            default: () => ('')
        },
        name: {
            type: String,
            default: () => ('')
        },
        label: {
            type: String,
            default: () => ('')
        },
        placeholder: {
            type: String,
            default: () => ('')
        },
        rules: {
            type: String,
            default: () => ('required')
        },
        disabled: {
            type: Boolean,
            default: () => (false)
        },
        required: {
            type: Boolean,
            default: () => (true)
        }
    },
    data() {

        return {
            valueData: this.value
        }
    },
    watch: {
        value() {
            this.valueData = this.value
        },
        valueData(value) {
            this.$emit('input', value)
        }
    },
    methods: {
        handleFocus() {
            this.$emit('focus')
        }
    }
}
</script>
