<template>
    <div class="form-group" :class="formGroupClasses">
      <slot name="label">
        <label v-if="label" class="form-control-label" :class="labelClasses">
          <strong v-if="required" class="text-danger">*</strong>
          {{ label }}
        </label>
      </slot>
      <div
        :class="[
          {'input-group': hasIcon},
          {'focused': focused},
          {'input-group-alternative': alternative},
          {'has-label': label || $slots.label},
          inputGroupClasses
        ]"
        :style="isBreak ? 'flex-basis: 55%;' : ''"
      >
        <div v-if="prependIcon || $slots.prepend" class="input-group-prepend">
          <div class="input-group-text" v-if="!prependRaw">
            <i :class="prependIcon" v-if="prependIcon"/>
            <slot name="prepend" />
          </div>
          <slot name="prepend" v-else />
        </div>
        <slot v-bind="slotData">
          <input
            :value="value"
            :type="type"
            v-on="listeners"
            v-bind="$attrs"
            :valid="error"
            :class="{invalid: error}"
            :required="required"
            class="form-control"
            v-if="type !== 'number'"
          >
          <input
            :value="value"
            :type="type"
            v-on="listeners"
            v-bind="$attrs"
            :valid="error"
            :class="{invalid: error}"
            :required="required"
            class="form-control"
            v-else
          >
        </slot>
        <div v-if="appendIcon || $slots.append" class="input-group-append">
          <div class="input-group-text" v-if="!appendRaw">
            <i :class="appendIcon" v-if="appendIcon"/>
            <slot name="append" />
          </div>
          <slot name="append" v-else/>
        </div>
        <slot name="infoBlock" />

      </div>
      <div v-if="error" class="mt-2"><small class="text-danger">{{error}}</small></div>
    </div>
</template>
<script>

import {has} from 'ramda'

export default {
  name: 'BaseInput',
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)'
    },
    group: {
      type: Boolean,
      description: 'Whether input is an input group (manual override in special cases)'
    },
    alternative: {
      type: Boolean,
      description: 'Whether input is of alternative layout'
    },
    label: {
      type: String,
      description: 'Input label (text before input)'
    },
    error: {
      type: String,
      description: 'Input error (below input)'
    },
    successMessage: {
      type: String,
      description: 'Input success message',
      default: ''
    },
    formGroupClasses: {
      type: String,
      description: 'Input label css classes',
      default: ''
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes',
      default: 'form-control-label'
    },
    inputClasses: {
      type: String,
      description: 'Input css classes'
    },
    inputGroupClasses: {
      type: String,
      description: 'Input group css classes'
    },
    value: {
      type: [String, Number],
      description: 'Input value'
    },
    type: {
      type: String,
      description: 'Input type',
      default: 'text'
    },
    appendRaw: {
      type: Boolean,
      default: false,
    },
    prependRaw: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String,
      description: 'Append icon (right)'
    },
    prependIcon: {
      type: String,
      description: 'Prepend icon (left)'
    },
    rules: {
      type: [String, Array, Object],
      description: 'Vee validate validation rules',
      default: ''
    },
    name: {
      type: String,
      description: 'Input name (used for validation)',
      default: ''
    },
    prefixName: {
      type: String,
      default: () => ('input.')
    },
    isBreak: {
      type: Boolean,
      default: () => (false)
    }
  },
  data () {
    return {
      focused: false
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
        change: this.onChange
      }
    },
    slotData () {
      return {
        focused: this.focused,
        error: this.error,
        ...this.listeners
      }
    },
    hasIcon () {
      const { append, prepend } = this.$slots
      return (
        append !== undefined ||
        prepend !== undefined ||
        this.appendIcon !== undefined ||
        this.prependIcon !== undefined ||
        this.group
      )
    },
  },
  methods: {
    updateValue (evt) {
      const value = evt.target.value
      this.$emit('input', value)
    },
    onFocus (evt) {
      this.focused = true
      this.$emit('focus', evt)
    },
    onBlur (evt) {
      this.focused = false
      this.$emit('blur', evt)
    },
    onChange (evt) {
      this.focused = false
      this.$emit('change', evt)
    }
  }
}
</script>

