<template>
  <div
    @mouseenter="$sidebar.onMouseEnter()"
    @mouseleave="$sidebar.onMouseLeave()"
    :data="backgroundColor"
    class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
  >
    <div
      :data="backgroundColor"
      class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white vpo-sidebar"
      @mouseover="mouseOver" @mouseleave="mouseLeave" :class="{
          'hovered': isHovered,
        }"
    >

      <div ref="sidebarScrollArea" class="scrollbar-inner position-relative vpo-scrollbar">
        <div class="d-none d-lg-block mb-2">
          <div class="sidenav-header d-flex align-items-center justify-content-center">
            <a class="navbar-brand flex-grow-1" href="#">
              <img src="/img/logo.svg" class="navbar-brand-img" alt="Sidebar logo" />
              <img src="/img/logo-text.svg" class="navbar-brand-text" alt="Sidebar logo" />
            </a>
            <!-- Sidenav toggler -->
            <div class="sidenav-toggler"
                :class="{'active': !$sidebar.isMinimized }"
                @click="minimizeSidebar">
              <svg width="25" height="17" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M29.1449 8.83333H2.47819C1.47819 8.83333 0.811523 9.5 0.811523 10.5C0.811523 11.5 1.47819 12.1667 2.47819 12.1667H29.1449C30.1449 12.1667 30.8115 11.5 30.8115 10.5C30.8115 9.5 30.1449 8.83333 29.1449 8.83333ZM2.47819 3.83333H29.1449C30.1449 3.83333 30.8115 3.16667 30.8115 2.16667C30.8115 1.16667 30.1449 0.5 29.1449 0.5H2.47819C1.47819 0.5 0.811523 1.16667 0.811523 2.16667C0.811523 3.16667 1.47819 3.83333 2.47819 3.83333ZM29.1449 17.1667H2.47819C1.47819 17.1667 0.811523 17.8333 0.811523 18.8333C0.811523 19.8333 1.47819 20.5 2.47819 20.5H29.1449C30.1449 20.5 30.8115 19.8333 30.8115 18.8333C30.8115 17.8333 30.1449 17.1667 29.1449 17.1667Z"
                  fill="white"/>
              </svg>
            </div>
          </div>
        </div>

        <!--      <div class="row">-->
        <!--        <a class="col-12 text-center navbar-brand text-purple" href="#">-->
        <!--          {{ name }}-->
        <!--        </a>-->
        <!--      </div>-->
        <slot/>
        <div class="navbar-inner">
          <ul class="navbar-nav">
            <slot name="links">
              <sidebar-item
                v-for="(link, index) in sidebarLinks"
                :key="link.name + index"
                :link="link"
              >
                <sidebar-item
                  v-for="(subLink, index) in link.children"
                  :key="subLink.name + index"
                  :link="subLink"
                />
              </sidebar-item>
            </slot>
          </ul>
          <slot name="links-after"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Sidebar',
    props: {
      title: {
        type: String,
        default: 'Creative Tim',
        description: 'Sidebar title'
      },
      shortTitle: {
        type: String,
        default: 'CT',
        description: 'Sidebar short title'
      },
      logo: {
        type: String,
        default: '/img/logo/logo_terra_horizontal.png',
        description: 'Sidebar app logo'
      },
      backgroundColor: {
        type: String,
        default: 'vue',
        validator: (value) => {
          const acceptedValues = [
            '',
            'vue',
            'blue',
            'green',
            'orange',
            'red',
            'primary'
          ]
          return acceptedValues.includes(value)
        },
        description:
          'Sidebar background color (vue|blue|green|orange|red|primary)'
      },
      sidebarLinks: {
        type: Array,
        default: () => [],
        description:
          'List of sidebar links as an array if you don\'t want to use components for these.'
      },
      autoClose: {
        type: Boolean,
        default: true,
        description:
          'Whether sidebar should autoclose on mobile when clicking an item'
      }
    },
    data() {
      const user = this.$store.getters['auth/user']
      return {
        isHovered: false,
        name: user.name,
        role: user.role
      }
    },
    provide() {
      return {
        // autoClose: this.autoClose
      }
    },
    mounted() {
      this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth
      this.minimizeSidebar()
    },
    methods: {
      mouseOver() {
        this.isHovered = true
      },
      mouseLeave() {
        this.isHovered = false
      },
      minimizeSidebar() {
        if (this.$sidebar) {
          this.$sidebar.toggleMinimize()
        }
      }
    },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false
      }
    },
  }
</script>
<style scoped lang="scss">
  .navbar-brand {

    .navbar-brand-img {
      max-width: 100%;
      max-height: 100%;
      width: 38px;
    }

    .navbar-brand-text {
      max-width: 100%;
      max-height: 100%;
      width: 97px;
      margin-left: 7px;
    }
  }

  .vpo-sidebar.hovered{
    max-width: 243px;
  }
  .vpo-scrollbar{
    overflow-x: hidden;
    overflow-y: auto;
  }
  .vpo-scrollbar::-webkit-scrollbar {
    display: none;
  }

</style>
