<template>
  <div :class="{[`bg-${type}`]: type}" class="header-container">
    <div class="container-fluid">
      <div class="header-body">
        <slot/>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'BaseHeader',
    props: {
      type: {
        type: String,
        default: '',
        description: 'Header background type'
      }
    }
  }
</script>
<style scoped lang="scss">
  .header-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 0;

    .header-body {
      .text-white {
        color: #000000 !important;
      }
    }
  }
</style>
